/* ALERGI KULIT */
export const READ_ALERGI_KULIT = "READ_ALERGI_KULIT";
export const READ_ALERGI_KULIT_REJECT = "READ_ALERGI_KULIT_REJECT";
export const READ_ALERGI_KULIT_FULFILLED = "READ_ALERGI_KULIT_FULFILLED";

export const CREATE_ALERGI_KULIT = "CREATE_ALERGI_KULIT";
export const CREATE_ALERGI_KULIT_REJECT = "CREATE_ALERGI_KULIT_REJECT";
export const CREATE_ALERGI_KULIT_FULFILLED = "CREATE_ALERGI_KULIT_FULFILLED";

export const UPDATE_ALERGI_KULIT = "UPDATE_ALERGI_KULIT";
export const UPDATE_ALERGI_KULIT_REJECT = "UPDATE_ALERGI_KULIT_REJECT";
export const UPDATE_ALERGI_KULIT_FULFILLED = "UPDATE_ALERGI_KULIT_FULFILLED";

export const DELETE_ALERGI_KULIT = "DELETE_ALERGI_KULIT";
export const DELETE_ALERGI_KULIT_REJECT = "DELETE_ALERGI_KULIT_REJECT";
export const DELETE_ALERGI_KULIT_FULFILLED = "DELETE_ALERGI_KULIT_FULFILLED";
