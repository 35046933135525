require("dotenv").config();

export const createInfoKondisiKulitImageMethod = async (data) => {
  const response = await fetch(`${process.env.REACT_APP_API}/info-kondisi-kulit-image`, {
    method: "POST",
    mode: "cors",
    body: data,
  });
  return response.json();
};

export const readInfoKondisiKulitImageMethod = async () => {
  const response = await fetch(`${process.env.REACT_APP_API}/info-kondisi-kulit-image`, {
    method: "GET",
    mode: "cors",
  });
  return response.json();
};

export const updateInfoKondisiKulitImageMethod = async ({ data, id }) => {
  const response = await fetch(`${process.env.REACT_APP_API}/info-kondisi-kulit-image/${id}`, {
    method: "PATCH",
    mode: "cors",
    body: data,
  });
  return response.json();
};

export const deleteInfoKondisiKulitImageMethod = async (id) => {
  const response = await fetch(`${process.env.REACT_APP_API}/info-kondisi-kulit-image/${id}`, {
    method: "DELETE",
    mode: "cors",
  });
  return response.json();
};
