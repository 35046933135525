// @flow
import {
  CREATE_ALERGI_PRODUCT,
  CREATE_ALERGI_PRODUCT_REJECT,
  CREATE_ALERGI_PRODUCT_FULFILLED,
  READ_ALERGI_PRODUCT,
  READ_ALERGI_PRODUCT_REJECT,
  READ_ALERGI_PRODUCT_FULFILLED,
  UPDATE_ALERGI_PRODUCT,
  UPDATE_ALERGI_PRODUCT_REJECT,
  UPDATE_ALERGI_PRODUCT_FULFILLED,
  DELETE_ALERGI_PRODUCT,
  DELETE_ALERGI_PRODUCT_REJECT,
  DELETE_ALERGI_PRODUCT_FULFILLED,
} from "./actionTypes";

const INIT_STATE = {
  alergi_product: null,
  message: null,
  loading: false,
};

const AlergiProduct = (state = INIT_STATE, action) => {
  switch (action.type) {
    case READ_ALERGI_PRODUCT:
      return {
        ...state,
        lading: true,
      };
    case READ_ALERGI_PRODUCT_REJECT:
      return {
        ...state,
        message: action.payload.message,
        lading: true,
      };
    case READ_ALERGI_PRODUCT_FULFILLED:
      return {
        ...state,
        alergi_product: action.payload.result,
        lading: false,
      };
    case CREATE_ALERGI_PRODUCT:
      return {
        ...state,
        loading: true,
      };
    case CREATE_ALERGI_PRODUCT_REJECT:
      return {
        ...state,
        message: action.payload.message,
        loading: true,
      };
    case CREATE_ALERGI_PRODUCT_FULFILLED:
      return {
        ...state,
        alergi_product: action.payload.result,
        loading: false,
      };
    case UPDATE_ALERGI_PRODUCT:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_ALERGI_PRODUCT_REJECT:
      return {
        ...state,
        message: action.payload.message,
        loading: true,
      };
    case UPDATE_ALERGI_PRODUCT_FULFILLED:
      return {
        ...state,
        alergi_product: action.payload.result,
        lading: false,
      };
    case DELETE_ALERGI_PRODUCT:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ALERGI_PRODUCT_REJECT:
      return {
        ...state,
        message: action.payload.message,
        loading: true,
      };
    case DELETE_ALERGI_PRODUCT_FULFILLED:
      return {
        ...state,
        alergi_product: action.payload.result,
        loading: false,
      };
    default:
      return state;
  }
};

export default AlergiProduct;
