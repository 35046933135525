import {
  CREATE_MASALAH_KULIT,
  CREATE_MASALAH_KULIT_REJECT,
  CREATE_MASALAH_KULIT_FULFILLED,
  READ_MASALAH_KULIT,
  READ_MASALAH_KULIT_REJECT,
  READ_MASALAH_KULIT_FULFILLED,
  UPDATE_MASALAH_KULIT,
  UPDATE_MASALAH_KULIT_REJECT,
  UPDATE_MASALAH_KULIT_FULFILLED,
  DELETE_MASALAH_KULIT,
  DELETE_MASALAH_KULIT_REJECT,
  DELETE_MASALAH_KULIT_FULFILLED,
} from "./actionTypes";

export const createMasalahKulit = (data) => {
  return {
    type: CREATE_MASALAH_KULIT,
    payload: data,
  };
};

export const createMasalahKulitReject = (payload) => {
  return {
    type: CREATE_MASALAH_KULIT_REJECT,
    payload: payload,
  };
};

export const createMasalahKulitFulfilled = (payload) => {
  return {
    type: CREATE_MASALAH_KULIT_FULFILLED,
    payload: payload,
  };
};

export const readMasalahKulit = (search_data) => {
  return {
    type: READ_MASALAH_KULIT,
    payload: search_data
  };
};

export const readMasalahKulitReject = (payload) => {
  return {
    type: READ_MASALAH_KULIT_REJECT,
    payload: payload,
  };
};

export const readMasalahKulitFulfilled = (data) => {
  return {
    type: READ_MASALAH_KULIT_FULFILLED,
    payload: data,
  };
};

export const updateMasalahKulit = (data, id, type) => {
  return {
    type: UPDATE_MASALAH_KULIT,
    payload: { data, id, type },
  };
};

export const updateMasalahKulitReject = (payload) => {
  return {
    type: UPDATE_MASALAH_KULIT_REJECT,
    payload: payload,
  };
};

export const updateMasalahKulitFulfilled = (payload) => {
  return {
    type: UPDATE_MASALAH_KULIT_FULFILLED,
    payload: payload,
  };
};

export const deleteMasalahKulit = (id) => {
  return {
    type: DELETE_MASALAH_KULIT,
    payload: id,
  };
};

export const deleteMasalahKulitReject = (payload) => {
  return {
    type: DELETE_MASALAH_KULIT_REJECT,
    payload: payload,
  };
};

export const deleteMasalahKulitFulfilled = (payload) => {
  return {
    type: DELETE_MASALAH_KULIT_FULFILLED,
    payload: payload,
  };
};
