import {
  CREATE_HOME_CARD,
  CREATE_HOME_CARD_REJECT,
  CREATE_HOME_CARD_FULFILLED,
  READ_HOME_CARD,
  READ_HOME_CARD_REJECT,
  READ_HOME_CARD_FULFILLED,
  UPDATE_HOME_CARD,
  UPDATE_HOME_CARD_REJECT,
  UPDATE_HOME_CARD_FULFILLED,
  DELETE_HOME_CARD,
  DELETE_HOME_CARD_REJECT,
  DELETE_HOME_CARD_FULFILLED,
} from "./actionTypes";

const INIT_STATE = {
  home_card: null,
  message: null,
  loading: false,
};

const Home = (state = INIT_STATE, action) => {
  switch (action.type) {
    case READ_HOME_CARD:
      return {
        ...state,
        lading: true,
      };
    case READ_HOME_CARD_REJECT:
      return {
        ...state,
        message: action.payload.message,
        lading: true,
      };
    case READ_HOME_CARD_FULFILLED:
      return {
        ...state,
        home_card: action.payload.result,
        lading: false,
      };
    case CREATE_HOME_CARD:
      return {
        ...state,
        loading: true,
      };
    case CREATE_HOME_CARD_REJECT:
      return {
        ...state,
        message: action.payload.message,
        loading: true,
      };
    case CREATE_HOME_CARD_FULFILLED:
      return {
        ...state,
        home_card: action.payload.result,
        loading: false,
      };
    case UPDATE_HOME_CARD:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_HOME_CARD_REJECT:
      return {
        ...state,
        message: action.payload.message,
        loading: true,
      };
    case UPDATE_HOME_CARD_FULFILLED:
      return {
        ...state,
        home_card: action.payload.result,
        lading: false,
      };
    case DELETE_HOME_CARD:
      return {
        ...state,
        loading: true,
      };
    case DELETE_HOME_CARD_REJECT:
      return {
        ...state,
        message: action.payload.message,
        loading: true,
      };
    case DELETE_HOME_CARD_FULFILLED:
      return {
        ...state,
        home_card: action.payload.result,
        loading: false,
      };
    default:
      return state;
  }
};

export default Home;
