require("dotenv").config();

export const createMasalahKulitMethod = async (data) => {
  const response = await fetch(`${process.env.REACT_APP_API}/masalah-kulit`, {
    method: "POST",
    mode: "cors",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  return response.json();
};

export const readMasalahKulitMethod = async (search_data) => {
  if (search_data !== "") {
    const response = await fetch(
      `${process.env.REACT_APP_API}/masalah-kulit/?name=${search_data}`,
      {
        method: "GET",
        mode: "cors",
      }
    );
    return response.json();
  } else {
    const response = await fetch(`${process.env.REACT_APP_API}/masalah-kulit`, {
      method: "GET",
      mode: "cors",
    });
    return response.json();
  }
};

export const updateMasalahKulitMethod = async ({ data, id, type }) => {
  if (type === "text") {
    const response = await fetch(
      `${process.env.REACT_APP_API}/masalah-kulit/${id}`,
      {
        method: "PATCH",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }
    );
    return response.json();
  } else {
    const response = await fetch(
      `${process.env.REACT_APP_API}/masalah-kulit/${id}`,
      {
        method: "PATCH",
        mode: "cors",
        body: data,
      }
    );
    return response.json();
  }
};

export const deleteMasalahKulitMethod = async (id) => {
  const response = await fetch(
    `${process.env.REACT_APP_API}/masalah-kulit/${id}`,
    {
      method: "DELETE",
      mode: "cors",
    }
  );
  return response.json();
};
