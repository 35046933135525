import React, { useEffect } from "react";
import { Container, Card, CardBody, CardTitle, Modal, Table } from "reactstrap";
import { readProduct } from "../../store/pages/product/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";

import Breadcrumbs from "../../components/Common/Breadcrumb";

const Product = (props) => {
  const product = props.product;
  const history = useHistory();

  const onSearch = (event) => {
    props.readProduct(event.target.value);
    history.push("/all-product");
  };

  useEffect(() => {
    props.readProduct("");
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Product List"} breadcrumbItem={"Product"} />
          <Card>
            <CardBody>
              <CardTitle>Product List</CardTitle>
              <div
                className="col-md-12 mb-3"
                style={{
                  display: "grid",
                  justifyItems: "flex-end",
                  gridTemplateColumns: "1fr max-content",
                  columnGap: "8px",
                }}
              >
                <input
                  placeholder="Search Product"
                  onChange={onSearch}
                  style={{
                    borderRadius: "25px",
                    padding: "4px",
                    borderWidth: "1px",
                    borderColor: "gray",
                    borderStyle: "solid",
                    width: "250px",
                  }}
                />
              </div>
              <div className="table-responsive">
                <Table className="table table-centered">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Image</th>
                      <th scope="col">Name</th>
                      <th scope="col">Stock</th>
                      <th scope="col">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {product &&
                      product.map((value, index) => {
                        return (
                          <tr key={value.id}>
                            <th scope="row">
                              <div>{index + 1}</div>
                            </th>
                            <td>
                              <img
                                src={value.thumbnail}
                                alt="Product"
                                width="100px"
                              />
                            </td>
                            <td>{value.title}</td>
                            <td>{value.stock}</td>
                            <td>{value.price}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { product } = state.Product;
  return { product };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      readProduct,
    },
    dispatch
  );

export default connect(mapStatetoProps, mapDispatchToProps)(Product);
