export const READ_ALERGI_PRODUCT = "READ_ALERGI_PRODUCT";
export const READ_ALERGI_PRODUCT_REJECT = "READ_ALERGI_PRODUCT_REJECT";
export const READ_ALERGI_PRODUCT_FULFILLED = "READ_ALERGI_PRODUCT_FULFILLED";

export const CREATE_ALERGI_PRODUCT = "CREATE_ALERGI_PRODUCT";
export const CREATE_ALERGI_PRODUCT_REJECT = "CREATE_ALERGI_PRODUCT_REJECT";
export const CREATE_ALERGI_PRODUCT_FULFILLED = "CREATE_ALERGI_PRODUCT_FULFILLED";

export const UPDATE_ALERGI_PRODUCT = "UPDATE_ALERGI_PRODUCT";
export const UPDATE_ALERGI_PRODUCT_REJECT = "UPDATE_ALERGI_PRODUCT_REJECT";
export const UPDATE_ALERGI_PRODUCT_FULFILLED = "UPDATE_ALERGI_PRODUCT_FULFILLED";

export const DELETE_ALERGI_PRODUCT = "DELETE_ALERGI_PRODUCT";
export const DELETE_ALERGI_PRODUCT_REJECT = "DELETE_ALERGI_PRODUCT_REJECT";
export const DELETE_ALERGI_PRODUCT_FULFILLED = "DELETE_ALERGI_PRODUCT_FULFILLED";
