import {
  READ_USER,
  READ_USER_REJECT,
  READ_USER_FULFILLED,
  DELETE_USER,
  DELETE_USER_REJECT,
  DELETE_USER_FULFILLED,
} from "./actionTypes";

const INIT_STATE = {
  user: null,
  first_page: null,
  last_page: null,
  active_page: null,
  start_index: null,
  end_index: null,
  next_page: null,
  previous_page: null,
  page_lists: null,
  message: null,
  loading: false,
};

const User = (state = INIT_STATE, action) => {
  switch (action.type) {
    case READ_USER:
      return {
        ...state,
        lading: true,
      };
    case READ_USER_REJECT:
      return {
        ...state,
        message: action.payload.message,
        lading: true,
      };
    case READ_USER_FULFILLED:
      return {
        ...state,
        user: action.payload.result,
        first_page: action.payload.first_page,
        last_page: action.payload.last_page,
        active_page: action.payload.active_page,
        page_lists: action.payload.page_lists,
        start_index: action.payload.start_index,
        end_index: action.payload.end_index,
        next_page: action.payload.next_page,
        previous_page: action.payload.previous_page || null,
        lading: false,
      };
    case DELETE_USER:
      return {
        ...state,
        loading: true,
      };
    case DELETE_USER_REJECT:
      return {
        ...state,
        message: action.payload.message,
        loading: true,
      };
    case DELETE_USER_FULFILLED:
      return {
        ...state,
        user: action.payload.result,
        loading: false,
      };
    default:
      return state;
  }
};

export default User;
