require("dotenv").config();

export const createHomeMethod = async (data) => {
  const response = await fetch(`${process.env.REACT_APP_API}/home`, {
    method: "POST",
    mode: "cors",
    body: data,
  });
  return response.json();
};

export const readHomeMethod = async () => {
  const response = await fetch(`${process.env.REACT_APP_API}/home`, {
    method: "GET",
    mode: "cors",
  });
  return response.json();
};

export const updateHomeMethod = async ({ data, id, type }) => {
  if (type === "text") {
    const response = await fetch(`${process.env.REACT_APP_API}/home/${id}`, {
      method: "PATCH",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });
    return response.json();
  } else {
    const response = await fetch(`${process.env.REACT_APP_API}/home/${id}`, {
      method: "PATCH",
      mode: "cors",
      body: data,
    });
    return response.json();
  }
};

export const deleteHomeMethod = async (id) => {
  const response = await fetch(`${process.env.REACT_APP_API}/home/${id}`, {
    method: "DELETE",
    mode: "cors",
  });
  return response.json();
};
