import {
  all,
  call,
  fork,
  takeEvery,
  put,
  takeLatest,
} from "redux-saga/effects";

import {
  CREATE_ALERGI_PRODUCT,
  READ_ALERGI_PRODUCT,
  UPDATE_ALERGI_PRODUCT,
  DELETE_ALERGI_PRODUCT,
} from "./actionTypes";
import {
  createAlergiProductReject,
  createAlergiProductFulfilled,
  readAlergiProductReject,
  readAlergiProductFulfilled,
  updateAlergiProductReject,
  updateAlergiProductFulfilled,
  deleteAlergiProductReject,
  deleteAlergiProductFulfilled,
} from "./actions";
import {
  createAlergiProductMethod,
  readAlergiProductMethod,
  updateAlergiProductMethod,
  deleteAlergiProductMethod,
} from "./method";

function* readAlergiProduct({ payload: search_data }) {
  const data = yield call(readAlergiProductMethod, search_data);
  if (data.status === 200) {
    yield put(readAlergiProductFulfilled(data));
  }
  yield put(readAlergiProductReject(data));
}
function* createAlergiProduct({ payload: data }) {
  const response = yield call(createAlergiProductMethod, data);
  if (response.status === 200) {
    yield put(createAlergiProductFulfilled(response));
  }
  yield put(createAlergiProductReject(createAlergiProductReject(response)));
}
function* updateAlergiProduct({ payload: { data, id } }) {
  const response = yield call(updateAlergiProductMethod, { data, id });
  if (response.status === 200) {
    yield put(updateAlergiProductFulfilled(response));
  }
  yield put(updateAlergiProductReject(updateAlergiProductReject(response)));
}
function* deleteAlergiProduct({ payload: id }) {
  const response = yield call(deleteAlergiProductMethod, id);
  if (response.status === 200) {
    yield put(deleteAlergiProductFulfilled(response));
  }
  yield put(deleteAlergiProductReject(deleteAlergiProductReject(response)));
}

export function* watchReadAlergiProduct() {
  yield takeLatest(READ_ALERGI_PRODUCT, readAlergiProduct);
}
export function* watchCreateAlergiProduct() {
  yield takeEvery(CREATE_ALERGI_PRODUCT, createAlergiProduct);
}
export function* watchUpdateAlergiProduct() {
  yield takeEvery(UPDATE_ALERGI_PRODUCT, updateAlergiProduct);
}
export function* watchDeleteAlergiProduct() {
  yield takeEvery(DELETE_ALERGI_PRODUCT, deleteAlergiProduct);
}

function* AlergiProductSaga() {
  yield all([
    fork(watchReadAlergiProduct),
    fork(watchCreateAlergiProduct),
    fork(watchUpdateAlergiProduct),
    fork(watchDeleteAlergiProduct),
  ]);
}

export default AlergiProductSaga;
