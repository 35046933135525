import {
  all,
  call,
  fork,
  takeEvery,
  put,
  takeLatest,
} from "redux-saga/effects";

import {
  CREATE_ALERGI_KULIT,
  READ_ALERGI_KULIT,
  UPDATE_ALERGI_KULIT,
  DELETE_ALERGI_KULIT,
} from "./actionTypes";
import {
  createAlergiKulitReject,
  createAlergiKulitFulfilled,
  readAlergiKulitReject,
  readAlergiKulitFulfilled,
  updateAlergiKulitReject,
  updateAlergiKulitFulfilled,
  deleteAlergiKulitReject,
  deleteAlergiKulitFulfilled,
} from "./actions";
import {
  createAlergiKulitMethod,
  readAlergiKulitMethod,
  updateAlergiKulitMethod,
  deleteAlergiKulitMethod,
} from "./method";

function* readAlergiKulit({ payload: search_data }) {
  const data = yield call(readAlergiKulitMethod, search_data);
  if (data.status === 200) {
    yield put(readAlergiKulitFulfilled(data));
  }
  yield put(readAlergiKulitReject(data));
}
function* createAlergiKulit({ payload: data }) {
  const response = yield call(createAlergiKulitMethod, data);
  if (response.status === 200) {
    yield put(createAlergiKulitFulfilled(response));
  }
  yield put(createAlergiKulitReject(createAlergiKulitReject(response)));
}
function* updateAlergiKulit({ payload: { data, id } }) {
  const response = yield call(updateAlergiKulitMethod, { data, id });
  if (response.status === 200) {
    yield put(updateAlergiKulitFulfilled(response));
  }
  yield put(updateAlergiKulitReject(updateAlergiKulitReject(response)));
}
function* deleteAlergiKulit({ payload: id }) {
  const response = yield call(deleteAlergiKulitMethod, id);
  if (response.status === 200) {
    yield put(deleteAlergiKulitFulfilled(response));
  }
  yield put(deleteAlergiKulitReject(deleteAlergiKulitReject(response)));
}

export function* watchReadAlergiKulit() {
  yield takeLatest(READ_ALERGI_KULIT, readAlergiKulit);
}
export function* watchCreateAlergiKulit() {
  yield takeEvery(CREATE_ALERGI_KULIT, createAlergiKulit);
}
export function* watchUpdateAlergiKulit() {
  yield takeEvery(UPDATE_ALERGI_KULIT, updateAlergiKulit);
}
export function* watchDeleteAlergiKulit() {
  yield takeEvery(DELETE_ALERGI_KULIT, deleteAlergiKulit);
}

function* AlergiKulitSaga() {
  yield all([
    fork(watchReadAlergiKulit),
    fork(watchCreateAlergiKulit),
    fork(watchUpdateAlergiKulit),
    fork(watchDeleteAlergiKulit),
  ]);
}

export default AlergiKulitSaga;
