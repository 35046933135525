import {
  CREATE_INFO_KONDISI_KULIT_IMAGE,
  CREATE_INFO_KONDISI_KULIT_IMAGE_REJECT,
  CREATE_INFO_KONDISI_KULIT_IMAGE_FULFILLED,
  READ_INFO_KONDISI_KULIT_IMAGE,
  READ_INFO_KONDISI_KULIT_IMAGE_REJECT,
  READ_INFO_KONDISI_KULIT_IMAGE_FULFILLED,
  UPDATE_INFO_KONDISI_KULIT_IMAGE,
  UPDATE_INFO_KONDISI_KULIT_IMAGE_REJECT,
  UPDATE_INFO_KONDISI_KULIT_IMAGE_FULFILLED,
  DELETE_INFO_KONDISI_KULIT_IMAGE,
  DELETE_INFO_KONDISI_KULIT_IMAGE_REJECT,
  DELETE_INFO_KONDISI_KULIT_IMAGE_FULFILLED,
} from "./actionTypes";

const INIT_STATE = {
  info_kondisi_kulit_image: null,
  message: null,
  loading: false,
};

const InfoKondisiKulitImage = (state = INIT_STATE, action) => {
  switch (action.type) {
    case READ_INFO_KONDISI_KULIT_IMAGE:
      return {
        ...state,
        lading: true,
      };
    case READ_INFO_KONDISI_KULIT_IMAGE_REJECT:
      return {
        ...state,
        message: action.payload.message,
        lading: true,
      };
    case READ_INFO_KONDISI_KULIT_IMAGE_FULFILLED:
      return {
        ...state,
        info_kondisi_kulit_image: action.payload.result,
        lading: false,
      };
    case CREATE_INFO_KONDISI_KULIT_IMAGE:
      return {
        ...state,
        loading: true,
      };
    case CREATE_INFO_KONDISI_KULIT_IMAGE_REJECT:
      return {
        ...state,
        message: action.payload.message,
        loading: true,
      };
    case CREATE_INFO_KONDISI_KULIT_IMAGE_FULFILLED:
      return {
        ...state,
        info_kondisi_kulit_image: action.payload.result,
        loading: false,
      };
    case UPDATE_INFO_KONDISI_KULIT_IMAGE:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_INFO_KONDISI_KULIT_IMAGE_REJECT:
      return {
        ...state,
        message: action.payload.message,
        loading: true,
      };
    case UPDATE_INFO_KONDISI_KULIT_IMAGE_FULFILLED:
      return {
        ...state,
        info_kondisi_kulit_image: action.payload.result,
        lading: false,
      };
    case DELETE_INFO_KONDISI_KULIT_IMAGE:
      return {
        ...state,
        loading: true,
      };
    case DELETE_INFO_KONDISI_KULIT_IMAGE_REJECT:
      return {
        ...state,
        message: action.payload.message,
        loading: true,
      };
    case DELETE_INFO_KONDISI_KULIT_IMAGE_FULFILLED:
      return {
        ...state,
        info_kondisi_kulit_image: action.payload.result,
        loading: false,
      };
    default:
      return state;
  }
};

export default InfoKondisiKulitImage;
