export const READ_INFO_KONDISI_KULIT_IMAGE = "READ_INFO_KONDISI_KULIT_IMAGE";
export const READ_INFO_KONDISI_KULIT_IMAGE_REJECT = "READ_INFO_KONDISI_KULIT_IMAGE_REJECT";
export const READ_INFO_KONDISI_KULIT_IMAGE_FULFILLED = "READ_INFO_KONDISI_KULIT_IMAGE_FULFILLED";

export const CREATE_INFO_KONDISI_KULIT_IMAGE = "CREATE_INFO_KONDISI_KULIT_IMAGE";
export const CREATE_INFO_KONDISI_KULIT_IMAGE_REJECT = "CREATE_INFO_KONDISI_KULIT_IMAGE_REJECT";
export const CREATE_INFO_KONDISI_KULIT_IMAGE_FULFILLED = "CREATE_INFO_KONDISI_KULIT_IMAGE_FULFILLED";

export const UPDATE_INFO_KONDISI_KULIT_IMAGE = "UPDATE_INFO_KONDISI_KULIT_IMAGE";
export const UPDATE_INFO_KONDISI_KULIT_IMAGE_REJECT = "UPDATE_INFO_KONDISI_KULIT_IMAGE_REJECT";
export const UPDATE_INFO_KONDISI_KULIT_IMAGE_FULFILLED = "UPDATE_INFO_KONDISI_KULIT_IMAGE_FULFILLED";

export const DELETE_INFO_KONDISI_KULIT_IMAGE = "DELETE_INFO_KONDISI_KULIT_IMAGE";
export const DELETE_INFO_KONDISI_KULIT_IMAGE_REJECT = "DELETE_INFO_KONDISI_KULIT_IMAGE_REJECT";
export const DELETE_INFO_KONDISI_KULIT_IMAGE_FULFILLED = "DELETE_INFO_KONDISI_KULIT_IMAGE_FULFILLED";
