import {
  CREATE_BASIC_INFO,
  CREATE_BASIC_INFO_REJECT,
  CREATE_BASIC_INFO_FULFILLED,
  READ_BASIC_INFO,
  READ_BASIC_INFO_REJECT,
  READ_BASIC_INFO_FULFILLED,
  UPDATE_BASIC_INFO,
  UPDATE_BASIC_INFO_REJECT,
  UPDATE_BASIC_INFO_FULFILLED,
  DELETE_BASIC_INFO,
  DELETE_BASIC_INFO_REJECT,
  DELETE_BASIC_INFO_FULFILLED,
} from "./actionTypes";

const INIT_STATE = {
  basic_info: null,
  message: null,
  loading: false,
};

const BasicInfo = (state = INIT_STATE, action) => {
  switch (action.type) {
    case READ_BASIC_INFO:
      return {
        ...state,
        lading: true,
      };
    case READ_BASIC_INFO_REJECT:
      return {
        ...state,
        message: action.payload.message,
        lading: true,
      };
    case READ_BASIC_INFO_FULFILLED:
      return {
        ...state,
        basic_info: action.payload.result,
        lading: false,
      };
    case CREATE_BASIC_INFO:
      return {
        ...state,
        loading: true,
      };
    case CREATE_BASIC_INFO_REJECT:
      return {
        ...state,
        message: action.payload.message,
        loading: true,
      };
    case CREATE_BASIC_INFO_FULFILLED:
      return {
        ...state,
        basic_info: action.payload.result,
        loading: false,
      };
    case UPDATE_BASIC_INFO:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_BASIC_INFO_REJECT:
      return {
        ...state,
        message: action.payload.message,
        loading: true,
      };
    case UPDATE_BASIC_INFO_FULFILLED:
      return {
        ...state,
        basic_info: action.payload.result,
        lading: false,
      };
    case DELETE_BASIC_INFO:
      return {
        ...state,
        loading: true,
      };
    case DELETE_BASIC_INFO_REJECT:
      return {
        ...state,
        message: action.payload.message,
        loading: true,
      };
    case DELETE_BASIC_INFO_FULFILLED:
      return {
        ...state,
        basic_info: action.payload.result,
        loading: false,
      };
    default:
      return state;
  }
};

export default BasicInfo;
