require("dotenv").config();

export const createKondisiKulitMethod = async (data) => {
  const response = await fetch(`${process.env.REACT_APP_API}/kondisi-kulit`, {
    method: "POST",
    mode: "cors",
    body: data,
  });
  return response.json();
};

export const readKondisiKulitMethod = async (search_data) => {
  if (search_data !== "") {
    const response = await fetch(
      `${process.env.REACT_APP_API}/kondisi-kulit/?name=${search_data}`,
      {
        method: "GET",
        mode: "cors",
      }
    );
    return response.json();
  } else {
    const response = await fetch(`${process.env.REACT_APP_API}/kondisi-kulit`, {
      method: "GET",
      mode: "cors",
    });
    return response.json();
  }
};

export const updateKondisiKulitMethod = async ({ data, id, type }) => {
  if (type === "text") {
    const response = await fetch(
      `${process.env.REACT_APP_API}/kondisi-kulit/${id}`,
      {
        method: "PATCH",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }
    );
    return response.json();
  } else {
    const response = await fetch(
      `${process.env.REACT_APP_API}/kondisi-kulit/${id}`,
      {
        method: "PATCH",
        mode: "cors",
        body: data,
      }
    );
    return response.json();
  }
};

export const deleteKondisiKulitMethod = async (id) => {
  const response = await fetch(
    `${process.env.REACT_APP_API}/kondisi-kulit/${id}`,
    {
      method: "DELETE",
      mode: "cors",
    }
  );
  return response.json();
};
