import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";
import KondisiKulit from "./pages/kondisiKulit/reducers";
import AlergiKulit from "./pages/alergiKulit/reducers";
import AlergiProduct from "./pages/alergiProduct/reducers";
import MasalahKulit from "./pages/masalahKulit/reducers";
import Product from "./pages/product/reducers";
import User from "./pages/user/reducers";
import Home from "./pages/home/reducers";
import HomeCard from "./pages/homeCard/reducers";
import BasicInfo from "./pages/basicInfo/reducers";
import InfoKondisiKulit from "./pages/infoKondisiKulit/reducers";
import InfoKondisiKulitImage from "./pages/infoKondisiKulitImage/reducers";
import ProfileAdmin from "./pages/profileAdmin/reducers";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  KondisiKulit,
  AlergiKulit,
  AlergiProduct,
  MasalahKulit,
  Product,
  User,
  Home,
  HomeCard,
  BasicInfo,
  InfoKondisiKulit,
  InfoKondisiKulitImage,
  ProfileAdmin
});

export default rootReducer;
