/* MASALAH KULIT */
export const READ_MASALAH_KULIT = "READ_MASALAH_KULIT";
export const READ_MASALAH_KULIT_REJECT = "READ_MASALAH_KULIT_REJECT";
export const READ_MASALAH_KULIT_FULFILLED = "READ_MASALAH_KULIT_FULFILLED";

export const CREATE_MASALAH_KULIT = "CREATE_MASALAH_KULIT";
export const CREATE_MASALAH_KULIT_REJECT = "CREATE_MASALAH_KULIT_REJECT";
export const CREATE_MASALAH_KULIT_FULFILLED = "CREATE_MASALAH_KULIT_FULFILLED";

export const UPDATE_MASALAH_KULIT = "UPDATE_MASALAH_KULIT";
export const UPDATE_MASALAH_KULIT_REJECT = "UPDATE_MASALAH_KULIT_REJECT";
export const UPDATE_MASALAH_KULIT_FULFILLED = "UPDATE_MASALAH_KULIT_FULFILLED";

export const DELETE_MASALAH_KULIT = "DELETE_MASALAH_KULIT";
export const DELETE_MASALAH_KULIT_REJECT = "DELETE_MASALAH_KULIT_REJECT";
export const DELETE_MASALAH_KULIT_FULFILLED = "DELETE_MASALAH_KULIT_FULFILLED";
