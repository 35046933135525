import {
  all,
  call,
  fork,
  takeEvery,
  put,
  takeLatest,
} from "redux-saga/effects";

import {
  CREATE_HOME_CARD,
  READ_HOME_CARD,
  UPDATE_HOME_CARD,
  DELETE_HOME_CARD,
} from "./actionTypes";
import {
  createHomeCardReject,
  createHomeCardFulfilled,
  readHomeCardReject,
  readHomeCardFulfilled,
  updateHomeCardReject,
  updateHomeCardFulfilled,
  deleteHomeCardReject,
  deleteHomeCardFulfilled,
} from "./actions";
import {
  createHomeCardMethod,
  readHomeCardMethod,
  updateHomeCardMethod,
  deleteHomeCardMethod,
} from "./method";

function* readHomeCard() {
  const data = yield call(readHomeCardMethod);
  if (data.status === 200) {
    yield put(readHomeCardFulfilled(data));
  }
  yield put(readHomeCardReject(data));
}
function* createHomeCard({ payload: data }) {
  const response = yield call(createHomeCardMethod, data);
  if (response.status === 200) {
    yield put(createHomeCardFulfilled(response));
  }
  yield put(createHomeCardReject(createHomeCardReject(response)));
}
function* updateHomeCard({ payload: { data, id, type } }) {
  const response = yield call(updateHomeCardMethod, { data, id, type });
  if (response.status === 200) {
    yield put(updateHomeCardFulfilled(response));
  }
  yield put(updateHomeCardReject(updateHomeCardReject(response)));
}
function* deleteHomeCard({ payload: id }) {
  const response = yield call(deleteHomeCardMethod, id);
  if (response.status === 200) {
    yield put(deleteHomeCardFulfilled(response));
  }
  yield put(deleteHomeCardReject(deleteHomeCardReject(response)));
}

export function* watchReadHomeCard() {
  yield takeLatest(READ_HOME_CARD, readHomeCard);
}
export function* watchCreateHomeCard() {
  yield takeEvery(CREATE_HOME_CARD, createHomeCard);
}
export function* watchUpdateHomeCard() {
  yield takeEvery(UPDATE_HOME_CARD, updateHomeCard);
}
export function* watchDeleteHomeCard() {
  yield takeEvery(DELETE_HOME_CARD, deleteHomeCard);
}

function* HomeCardSaga() {
  yield all([
    fork(watchReadHomeCard),
    fork(watchCreateHomeCard),
    fork(watchUpdateHomeCard),
    fork(watchDeleteHomeCard),
  ]);
}

export default HomeCardSaga;
