import {
  CREATE_BASIC_INFO,
  CREATE_BASIC_INFO_REJECT,
  CREATE_BASIC_INFO_FULFILLED,
  READ_BASIC_INFO,
  READ_BASIC_INFO_REJECT,
  READ_BASIC_INFO_FULFILLED,
  UPDATE_BASIC_INFO,
  UPDATE_BASIC_INFO_REJECT,
  UPDATE_BASIC_INFO_FULFILLED,
  DELETE_BASIC_INFO,
  DELETE_BASIC_INFO_REJECT,
  DELETE_BASIC_INFO_FULFILLED,
} from "./actionTypes";

export const createBasicInfo = (data) => {
  return {
    type: CREATE_BASIC_INFO,
    payload: data,
  };
};

export const createBasicInfoReject = (payload) => {
  return {
    type: CREATE_BASIC_INFO_REJECT,
    payload: payload,
  };
};

export const createBasicInfoFulfilled = (payload) => {
  return {
    type: CREATE_BASIC_INFO_FULFILLED,
    payload: payload,
  };
};

export const readBasicInfo = () => {
  return {
    type: READ_BASIC_INFO,
  };
};

export const readBasicInfoReject = (payload) => {
  return {
    type: READ_BASIC_INFO_REJECT,
    payload: payload,
  };
};

export const readBasicInfoFulfilled = (data) => {
  return {
    type: READ_BASIC_INFO_FULFILLED,
    payload: data,
  };
};

export const updateBasicInfo = (data, id, type) => {
  return {
    type: UPDATE_BASIC_INFO,
    payload: { data, id, type },
  };
};

export const updateBasicInfoReject = (payload) => {
  return {
    type: UPDATE_BASIC_INFO_REJECT,
    payload: payload,
  };
};

export const updateBasicInfoFulfilled = (payload) => {
  return {
    type: UPDATE_BASIC_INFO_FULFILLED,
    payload: payload,
  };
};

export const deleteBasicInfo = (id) => {
  return {
    type: DELETE_BASIC_INFO,
    payload: id,
  };
};

export const deleteBasicInfoReject = (payload) => {
  return {
    type: DELETE_BASIC_INFO_REJECT,
    payload: payload,
  };
};

export const deleteBasicInfoFulfilled = (payload) => {
  return {
    type: DELETE_BASIC_INFO_FULFILLED,
    payload: payload,
  };
};
