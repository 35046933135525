import {
  all,
  call,
  fork,
  takeEvery,
  put,
  takeLatest,
} from "redux-saga/effects";

import {
  CREATE_INFO_KONDISI_KULIT_IMAGE,
  READ_INFO_KONDISI_KULIT_IMAGE,
  UPDATE_INFO_KONDISI_KULIT_IMAGE,
  DELETE_INFO_KONDISI_KULIT_IMAGE,
} from "./actionTypes";
import {
  createInfoKondisiKulitImageReject,
  createInfoKondisiKulitImageFulfilled,
  readInfoKondisiKulitImageReject,
  readInfoKondisiKulitImageFulfilled,
  updateInfoKondisiKulitImageReject,
  updateInfoKondisiKulitImageFulfilled,
  deleteInfoKondisiKulitImageReject,
  deleteInfoKondisiKulitImageFulfilled,
} from "./actions";
import {
  createInfoKondisiKulitImageMethod,
  readInfoKondisiKulitImageMethod,
  updateInfoKondisiKulitImageMethod,
  deleteInfoKondisiKulitImageMethod,
} from "./method";

function* readInfoKondisiKulitImage() {
  const data = yield call(readInfoKondisiKulitImageMethod);
  if (data.status === 200) {
    yield put(readInfoKondisiKulitImageFulfilled(data));
  }
  yield put(readInfoKondisiKulitImageReject(data));
}
function* createInfoKondisiKulitImage({ payload: data }) {
  const response = yield call(createInfoKondisiKulitImageMethod, data);
  if (response.status === 200) {
    yield put(createInfoKondisiKulitImageFulfilled(response));
  }
  yield put(createInfoKondisiKulitImageReject(createInfoKondisiKulitImageReject(response)));
}
function* updateInfoKondisiKulitImage({ payload: { data, id } }) {
  const response = yield call(updateInfoKondisiKulitImageMethod, { data, id});
  if (response.status === 200) {
    yield put(updateInfoKondisiKulitImageFulfilled(response));
  }
  yield put(updateInfoKondisiKulitImageReject(updateInfoKondisiKulitImageReject(response)));
}
function* deleteInfoKondisiKulitImage({ payload: id }) {
  const response = yield call(deleteInfoKondisiKulitImageMethod, id);
  if (response.status === 200) {
    yield put(deleteInfoKondisiKulitImageFulfilled(response));
  }
  yield put(deleteInfoKondisiKulitImageReject(deleteInfoKondisiKulitImageReject(response)));
}

export function* watchReadInfoKondisiKulitImage() {
  yield takeLatest(READ_INFO_KONDISI_KULIT_IMAGE, readInfoKondisiKulitImage);
}
export function* watchCreateInfoKondisiKulitImage() {
  yield takeEvery(CREATE_INFO_KONDISI_KULIT_IMAGE, createInfoKondisiKulitImage);
}
export function* watchUpdateInfoKondisiKulitImage() {
  yield takeEvery(UPDATE_INFO_KONDISI_KULIT_IMAGE, updateInfoKondisiKulitImage);
}
export function* watchDeleteInfoKondisiKulitImage() {
  yield takeEvery(DELETE_INFO_KONDISI_KULIT_IMAGE, deleteInfoKondisiKulitImage);
}

function* InfoKondisiKulitImageSaga() {
  yield all([
    fork(watchReadInfoKondisiKulitImage),
    fork(watchCreateInfoKondisiKulitImage),
    fork(watchUpdateInfoKondisiKulitImage),
    fork(watchDeleteInfoKondisiKulitImage),
  ]);
}

export default InfoKondisiKulitImageSaga;
