import {
  all,
  call,
  fork,
  takeEvery,
  put,
  takeLatest,
} from "redux-saga/effects";

import {
  CREATE_KONDISI_KULIT,
  READ_KONDISI_KULIT,
  UPDATE_KONDISI_KULIT,
  DELETE_KONDISI_KULIT,
} from "./actionTypes";
import {
  createKondisiKulitReject,
  createKondisiKulitFulfilled,
  readKondisiKulitReject,
  readKondisiKulitFulfilled,
  updateKondisiKulitReject,
  updateKondisiKulitFulfilled,
  deleteKondisiKulitReject,
  deleteKondisiKulitFulfilled,
} from "./actions";
import {
  createKondisiKulitMethod,
  readKondisiKulitMethod,
  updateKondisiKulitMethod,
  deleteKondisiKulitMethod,
} from "./method";

function* readKondisiKulit({ payload: search_data }) {
  const data = yield call(readKondisiKulitMethod, search_data);
  if (data.status === 200) {
    yield put(readKondisiKulitFulfilled(data));
  }
  yield put(readKondisiKulitReject(data));
}
function* createKondisiKulit({ payload: data }) {
  const response = yield call(createKondisiKulitMethod, data);
  if (response.status === 200) {
    yield put(createKondisiKulitFulfilled(response));
  }
  yield put(createKondisiKulitReject(createKondisiKulitReject(response)));
}
function* updateKondisiKulit({ payload: { data, id, type } }) {
  const response = yield call(updateKondisiKulitMethod, { data, id, type });
  if (response.status === 200) {
    yield put(updateKondisiKulitFulfilled(response));
  }
  yield put(updateKondisiKulitReject(updateKondisiKulitReject(response)));
}
function* deleteKondisiKulit({ payload: id }) {
  const response = yield call(deleteKondisiKulitMethod, id);
  if (response.status === 200) {
    yield put(deleteKondisiKulitFulfilled(response));
  }
  yield put(deleteKondisiKulitReject(deleteKondisiKulitReject(response)));
}

export function* watchReadKondisiKulit() {
  yield takeLatest(READ_KONDISI_KULIT, readKondisiKulit);
}
export function* watchCreateKondisiKulit() {
  yield takeEvery(CREATE_KONDISI_KULIT, createKondisiKulit);
}
export function* watchUpdateKondisiKulit() {
  yield takeEvery(UPDATE_KONDISI_KULIT, updateKondisiKulit);
}
export function* watchDeleteKondisiKulit() {
  yield takeEvery(DELETE_KONDISI_KULIT, deleteKondisiKulit);
}

function* KondisiKulitSaga() {
  yield all([
    fork(watchReadKondisiKulit),
    fork(watchCreateKondisiKulit),
    fork(watchUpdateKondisiKulit),
    fork(watchDeleteKondisiKulit),
  ]);
}

export default KondisiKulitSaga;
