import {
  all,
  call,
  fork,
  takeEvery,
  put,
  takeLatest,
} from "redux-saga/effects";

import { READ_USER, DELETE_USER } from "./actionTypes";
import {
  readUserReject,
  readUserFulfilled,
  deleteUserReject,
  deleteUserFulfilled,
} from "./actions";
import { readUserMethod, deleteUserMethod } from "./method";

function* readUser({ payload: { date, name, limit, page, kondisiKulit, alergiKulit, masalahKulit } }) {
  const data = yield call(readUserMethod, { date, name, limit, page, kondisiKulit, alergiKulit, masalahKulit });
  if (data.status === 200) {
    yield put(readUserFulfilled(data));
  }
  yield put(readUserReject(data));
}
function* deleteUser({ payload: id }) {
  const response = yield call(deleteUserMethod, id);
  if (response.status === 200) {
    yield put(deleteUserFulfilled(response));
  }
  yield put(deleteUserReject(deleteUserReject(response)));
}

export function* watchReadUser() {
  yield takeLatest(READ_USER, readUser);
}
export function* watchDeleteUser() {
  yield takeEvery(DELETE_USER, deleteUser);
}

function* UserSaga() {
  yield all([fork(watchReadUser), fork(watchDeleteUser)]);
}

export default UserSaga;
