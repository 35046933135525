import {
  CREATE_INFO_KONDISI_KULIT_IMAGE,
  CREATE_INFO_KONDISI_KULIT_IMAGE_REJECT,
  CREATE_INFO_KONDISI_KULIT_IMAGE_FULFILLED,
  READ_INFO_KONDISI_KULIT_IMAGE,
  READ_INFO_KONDISI_KULIT_IMAGE_REJECT,
  READ_INFO_KONDISI_KULIT_IMAGE_FULFILLED,
  UPDATE_INFO_KONDISI_KULIT_IMAGE,
  UPDATE_INFO_KONDISI_KULIT_IMAGE_REJECT,
  UPDATE_INFO_KONDISI_KULIT_IMAGE_FULFILLED,
  DELETE_INFO_KONDISI_KULIT_IMAGE,
  DELETE_INFO_KONDISI_KULIT_IMAGE_REJECT,
  DELETE_INFO_KONDISI_KULIT_IMAGE_FULFILLED,
} from "./actionTypes";

export const createInfoKondisiKulitImage = (data) => {
  return {
    type: CREATE_INFO_KONDISI_KULIT_IMAGE,
    payload: data,
  };
};

export const createInfoKondisiKulitImageReject = (payload) => {
  return {
    type: CREATE_INFO_KONDISI_KULIT_IMAGE_REJECT,
    payload: payload,
  };
};

export const createInfoKondisiKulitImageFulfilled = (payload) => {
  return {
    type: CREATE_INFO_KONDISI_KULIT_IMAGE_FULFILLED,
    payload: payload,
  };
};

export const readInfoKondisiKulitImage = () => {
  return {
    type: READ_INFO_KONDISI_KULIT_IMAGE,
  };
};

export const readInfoKondisiKulitImageReject = (payload) => {
  return {
    type: READ_INFO_KONDISI_KULIT_IMAGE_REJECT,
    payload: payload,
  };
};

export const readInfoKondisiKulitImageFulfilled = (data) => {
  return {
    type: READ_INFO_KONDISI_KULIT_IMAGE_FULFILLED,
    payload: data,
  };
};

export const updateInfoKondisiKulitImage = (data, id) => {
  return {
    type: UPDATE_INFO_KONDISI_KULIT_IMAGE,
    payload: { data, id },
  };
};

export const updateInfoKondisiKulitImageReject = (payload) => {
  return {
    type: UPDATE_INFO_KONDISI_KULIT_IMAGE_REJECT,
    payload: payload,
  };
};

export const updateInfoKondisiKulitImageFulfilled = (payload) => {
  return {
    type: UPDATE_INFO_KONDISI_KULIT_IMAGE_FULFILLED,
    payload: payload,
  };
};

export const deleteInfoKondisiKulitImage = (id) => {
  return {
    type: DELETE_INFO_KONDISI_KULIT_IMAGE,
    payload: id,
  };
};

export const deleteInfoKondisiKulitImageReject = (payload) => {
  return {
    type: DELETE_INFO_KONDISI_KULIT_IMAGE_REJECT,
    payload: payload,
  };
};

export const deleteInfoKondisiKulitImageFulfilled = (payload) => {
  return {
    type: DELETE_INFO_KONDISI_KULIT_IMAGE_FULFILLED,
    payload: payload,
  };
};
