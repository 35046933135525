require("dotenv").config();

export const readUserMethod = async ({date, name, limit, page, kondisiKulit, alergiKulit, masalahKulit}) => {
  if (date.start && date.end) {
    const response = await fetch(
      `${process.env.REACT_APP_API}/user?start=${date.start}&end=${date.end}&name=${name}&kondisi_kulit_id=${kondisiKulit}&alergi_kulit_id=${alergiKulit}&masalah_kulit_id=${masalahKulit}&limit=${limit}&page=${page}`,
      {
        method: "GET",
        mode: "cors",
      }
    );
    return response.json();
  } else {
    const response = await fetch(
      `${process.env.REACT_APP_API}/user?name=${name}&kondisi_kulit_id=${kondisiKulit}&alergi_kulit_id=${alergiKulit}&masalah_kulit_id=${masalahKulit}&limit=${limit}&page=${page}`,
      {
        method: "GET",
        mode: "cors",
      }
    );
    return response.json();
  }
};

export const deleteUserMethod = async (id) => {
  const response = await fetch(`${process.env.REACT_APP_API}/user/${id}`, {
    method: "DELETE",
    mode: "cors",
  });
  return response.json();
};
