import { all } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import KondisiKulitSaga from "./pages/kondisiKulit/saga";
import AlergiKulitSaga from "./pages/alergiKulit/saga";
import AlergiProductSaga from "./pages/alergiProduct/saga";
import MasalahKulitSaga from "./pages/masalahKulit/saga";
import ProductSaga from "./pages/product/saga";
import UserSaga from "./pages/user/saga";
import HomeSaga from "./pages/home/saga";
import HomeCardSaga from "./pages/homeCard/saga";
import BasicInfoSaga from "./pages/basicInfo/saga";
import InfoKondisiKulitSaga from "./pages/infoKondisiKulit/saga";
import InfoKondisiKulitSagaImage from "./pages/infoKondisiKulitImage/saga";
import ProfileAdmin from "./pages/profileAdmin/saga";

export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    AuthSaga(),
    ProfileSaga(),
    ForgetSaga(),
    LayoutSaga(),
    KondisiKulitSaga(),
    AlergiKulitSaga(),
    AlergiProductSaga(),
    MasalahKulitSaga(),
    ProductSaga(),
    UserSaga(),
    HomeSaga(),
    HomeCardSaga(),
    BasicInfoSaga(),
    InfoKondisiKulitSaga(),
    InfoKondisiKulitSagaImage(),
    ProfileAdmin()
  ]);
}
