import {
  CREATE_KONDISI_KULIT,
  CREATE_KONDISI_KULIT_REJECT,
  CREATE_KONDISI_KULIT_FULFILLED,
  READ_KONDISI_KULIT,
  READ_KONDISI_KULIT_REJECT,
  READ_KONDISI_KULIT_FULFILLED,
  UPDATE_KONDISI_KULIT,
  UPDATE_KONDISI_KULIT_REJECT,
  UPDATE_KONDISI_KULIT_FULFILLED,
  DELETE_KONDISI_KULIT,
  DELETE_KONDISI_KULIT_REJECT,
  DELETE_KONDISI_KULIT_FULFILLED,
} from "./actionTypes";

export const createKondisiKulit = (data) => {
  return {
    type: CREATE_KONDISI_KULIT,
    payload: data,
  };
};

export const createKondisiKulitReject = (payload) => {
  return {
    type: CREATE_KONDISI_KULIT_REJECT,
    payload: payload,
  };
};

export const createKondisiKulitFulfilled = (payload) => {
  return {
    type: CREATE_KONDISI_KULIT_FULFILLED,
    payload: payload,
  };
};

export const readKondisiKulit = (search_data) => {
  return {
    type: READ_KONDISI_KULIT,
    payload: search_data
  };
};

export const readKondisiKulitReject = (payload) => {
  return {
    type: READ_KONDISI_KULIT_REJECT,
    payload: payload,
  };
};

export const readKondisiKulitFulfilled = (data) => {
  return {
    type: READ_KONDISI_KULIT_FULFILLED,
    payload: data,
  };
};

export const updateKondisiKulit = (data, id, type) => {
  return {
    type: UPDATE_KONDISI_KULIT,
    payload: { data, id, type },
  };
};

export const updateKondisiKulitReject = (payload) => {
  return {
    type: UPDATE_KONDISI_KULIT_REJECT,
    payload: payload,
  };
};

export const updateKondisiKulitFulfilled = (payload) => {
  return {
    type: UPDATE_KONDISI_KULIT_FULFILLED,
    payload: payload,
  };
};

export const deleteKondisiKulit = (id) => {
  return {
    type: DELETE_KONDISI_KULIT,
    payload: id,
  };
};

export const deleteKondisiKulitReject = (payload) => {
  return {
    type: DELETE_KONDISI_KULIT_REJECT,
    payload: payload,
  };
};

export const deleteKondisiKulitFulfilled = (payload) => {
  return {
    type: DELETE_KONDISI_KULIT_FULFILLED,
    payload: payload,
  };
};
