import React, {useState, useEffect} from 'react'
import { Container, Card, CardBody, CardTitle, Table, Modal } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import useAsync from "@alifaishol/use-async";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const getData = async () => {
  const response = await fetch(`${process.env.REACT_APP_API}/analisis`)
  return response.json();
}

const createData = async (data) => {
  const response = await fetch(`${process.env.REACT_APP_API}/analisis`, {
    method: 'POST',
    mode: 'cors',
    body: data,
  })
  return response.json();
}

const updateData = async (data, id) => {
  const response = await fetch(`${process.env.REACT_APP_API}/analisis/${id}`, {
    method: 'PATCH',
    mode: 'cors',
    body: data
  })
  return response.json();
}

const Analisis = () => {
  const {exec, state: {data}} = useAsync(getData)
  const {exec: createDataExec} = useAsync(createData, {
    onSuccess: () => {
      exec();
      alert('Analisis Updated Successfully!');
    },
    onError: (err) => {
      exec();
      alert(err.message);
    }
  })
  const {exec: updateDataExec} = useAsync(updateData, {
    onSuccess: () => {
      exec();
      alert('Analisis Updated Successfully!');
    },
    onError: (err) => {
      exec();
      alert(err.message);
    }
  })
  const [formData, setFormData] = useState();
  const [modalUpdate, setModalUpdate] = useState(false)
  const [modalCreate, setModalCreate] = useState(false)
  const [selectedData, setSelectedData] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageData, setImageData] = useState(null);

  const analisis = data && data.result;

  const onInsertImage = async (event) => {
    const image = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImageUrl(reader.result);
        setImageData(image);
      }
    };
    reader.readAsDataURL(image);
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const onChangeData = (event) => {
    setFormData(ps => ({
      ...ps,
      [event.target.name]: event.target.value,
    }));
  };
  const onChangeSelectedData = (event) => {
    setSelectedData({
      ...selectedData,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmitCreate = () => {
    const fd = new FormData();
    fd.append('title', formData.title)
    fd.append('description', formData.description)
    fd.append('warning', formData.warning)
    fd.append('image', imageData)
    createDataExec(fd);
    setModalCreate(!modalCreate);
    removeBodyCss();
    setFormData(null);
  };

  const onSubmitUpdate = () => {
    const fd = new FormData();
    fd.append('title', selectedData.title)
    fd.append('description', selectedData.description)
    fd.append('warning', selectedData.warning)
    fd.append('image', imageData)
    updateDataExec(fd, selectedData.id);
    setModalUpdate(!modalUpdate);
    removeBodyCss();
    setSelectedData(null);
  };

  useEffect(() => {
    exec()
  }, [exec])

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Analisis"} breadcrumbItem={"Analisis"} />
          <Card>
            <CardBody>
              <CardTitle>Analisis</CardTitle>
              <div
                className="col-md-12 mb-3"
                style={{ display: "grid", justifyItems: "flex-end" }}
              >
                {analisis && analisis.length <= 0 ? (
                  <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={() => setModalCreate(!modalCreate)}
                  >
                    <i className="bx bx-edit-alt font-size-16 align-middle mr-2"></i>{" "}
                    Insert
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={() => {
                      setModalUpdate(!modalUpdate);
                      setSelectedData(analisis[0]);
                      setImageUrl(analisis[0].image)
                    }}
                  >
                    <i className="bx bx-edit font-size-16 align-middle mr-2"></i>{" "}
                    Edit
                  </button>
                )}
              </div>
              {analisis && analisis.length > 0 && (
                <div className="table-responsive">
                  <Table className="table table-centered table-bordered">
                    <tbody>
                      <tr>
                        <th>Title</th>
                        <td>{analisis && analisis[0].title}</td>
                      </tr>
                      <tr>
                        <th>Description</th>
                        <td>{analisis && analisis[0].description}</td>
                      </tr>
                      <tr>
                        <th>Warning</th>
                        <td>{analisis && analisis[0].warning}</td>
                      </tr>
                      <tr>
                        <th>Image</th>
                        <td>
                          <img
                            src={analisis && analisis[0].image}
                            alt="Analisis Background"
                            width="500px"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}
            </CardBody>
          </Card>
          {/* Modal Create */}
          <Modal
            isOpen={modalCreate}
            toggle={() => {
              setModalCreate(!modalCreate);
              removeBodyCss();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Insert Basic Info Component
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalCreate(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <AvForm>
                <AvField
                  name="title"
                  label="Title"
                  value={data && data.title}
                  placeholder="ex: Skin Advisor"
                  type="text"
                  errorMessage="Enter Title"
                  validate={{ required: { value: true } }}
                  onChange={onChangeData}
                />
                <AvField
                  name="description"
                  label="Description"
                  value={data && data.description}
                  placeholder="ex: 	Skin Health is Number One"
                  type="text"
                  errorMessage="Enter Description"
                  validate={{ required: { value: true } }}
                  onChange={onChangeData}
                />
                <AvField
                  name="warning"
                  label="Warning"
                  value={data && data.warning}
                  placeholder="ex: 	Skin Health is Number One"
                  type="text"
                  errorMessage="Enter Warning"
                  validate={{ required: { value: true } }}
                  onChange={onChangeData}
                />
                <label className="col-form-label">Image</label>
                <div
                  style={{
                    borderStyle: "dashed",
                    borderWidth: "2px",
                    borderColor: "primary",
                    borderRadius: "10px",
                    position: "relative",
                    minHeight: "250px",
                    display: "grid",
                    alignItems: "center",
                  }}
                >
                  {imageUrl === null ? (
                    <div
                      style={{
                        display: "grid",
                        justifyItems: "center",
                        fontSize: "24px",
                      }}
                    >
                      <i className="bx bx-cloud-upload align-middle"></i>
                      <div>Upload Image</div>
                    </div>
                  ) : (
                    <img
                      src={imageUrl}
                      style={{ width: "100%", borderRadius: "10px" }}
                      alt="BasicInfo"
                    />
                  )}
                </div>
                <input
                  id="upload-basic_info-image"
                  style={{ display: "none" }}
                  accept="image/*"
                  type="file"
                  onChange={onInsertImage}
                />
                <div className="text-center mt-4">
                  <label
                    type="button"
                    htmlFor="upload-basic_info-image"
                    className="btn btn-primary waves-effect waves-light"
                  >
                    Upload File
                  </label>
                </div>
              </AvForm>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setModalCreate(!modalCreate);
                  removeBodyCss();
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light"
                onClick={onSubmitCreate}
              >
                Submit
              </button>
            </div>
          </Modal>

          {/* Modal Update */}
          <Modal
            isOpen={modalUpdate}
            toggle={() => {
              setModalUpdate(!modalUpdate);
              removeBodyCss();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Edit Basic Info Component
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalUpdate(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <AvForm>
                <AvField
                  name="title"
                  label="Title"
                  value={selectedData && selectedData.title}
                  placeholder="ex: Skin Advisor"
                  type="text"
                  errorMessage="Enter Title"
                  validate={{ required: { value: true } }}
                  onChange={onChangeSelectedData}
                />
                <AvField
                  name="description"
                  label="Description"
                  value={selectedData && selectedData.description}
                  placeholder="ex: Skin Health is Number One"
                  type="text"
                  errorMessage="Enter Description"
                  validate={{ required: { value: true } }}
                  onChange={onChangeSelectedData}
                />
                <AvField
                  name="warning"
                  label="Warning"
                  value={selectedData && selectedData.warning}
                  placeholder="ex: Skin Health is Number One"
                  type="text"
                  errorMessage="Enter Warning"
                  validate={{ required: { value: true } }}
                  onChange={onChangeSelectedData}
                />
                <label className="col-form-label">Image</label>
                <div
                  style={{
                    borderStyle: "dashed",
                    borderWidth: "2px",
                    borderColor: "primary",
                    borderRadius: "10px",
                    position: "relative",
                    minHeight: "250px",
                    display: "grid",
                    alignItems: "center",
                  }}
                >
                  {imageUrl === null ? (
                    <div
                      style={{
                        display: "grid",
                        justifyItems: "center",
                        fontSize: "24px",
                      }}
                    >
                      <i className="bx bx-cloud-upload align-middle"></i>
                      <div>Upload Image</div>
                    </div>
                  ) : (
                    <img
                      src={imageUrl}
                      style={{ width: "100%", borderRadius: "10px" }}
                      alt="BasicInfo"
                    />
                  )}
                </div>
                <input
                  id="upload-basic_info-image"
                  style={{ display: "none" }}
                  accept="image/*"
                  type="file"
                  onChange={onInsertImage}
                />
                <div className="text-center mt-4">
                  <label
                    type="button"
                    htmlFor="upload-basic_info-image"
                    className="btn btn-primary waves-effect waves-light"
                  >
                    Upload File
                  </label>
                </div>
              </AvForm>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setModalUpdate(!modalUpdate);
                  removeBodyCss();
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light"
                onClick={onSubmitUpdate}
              >
                Submit
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </>
  )
}

export default Analisis
