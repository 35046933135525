import {
  all,
  call,
  fork,
  takeEvery,
  put,
  takeLatest,
} from "redux-saga/effects";

import {
  CREATE_MASALAH_KULIT,
  READ_MASALAH_KULIT,
  UPDATE_MASALAH_KULIT,
  DELETE_MASALAH_KULIT,
} from "./actionTypes";
import {
  createMasalahKulitReject,
  createMasalahKulitFulfilled,
  readMasalahKulitReject,
  readMasalahKulitFulfilled,
  updateMasalahKulitReject,
  updateMasalahKulitFulfilled,
  deleteMasalahKulitReject,
  deleteMasalahKulitFulfilled,
} from "./actions";
import {
  createMasalahKulitMethod,
  readMasalahKulitMethod,
  updateMasalahKulitMethod,
  deleteMasalahKulitMethod,
} from "./method";

function* readMasalahKulit({ payload: search_data }) {
  const data = yield call(readMasalahKulitMethod, search_data);
  if (data.status === 200) {
    yield put(readMasalahKulitFulfilled(data));
  }
  yield put(readMasalahKulitReject(data));
}
function* createMasalahKulit({ payload: data }) {
  const response = yield call(createMasalahKulitMethod, data);
  if (response.status === 200) {
    yield put(createMasalahKulitFulfilled(response));
  }
  yield put(createMasalahKulitReject(createMasalahKulitReject(response)));
}
function* updateMasalahKulit({ payload: { data, id, type } }) {
  const response = yield call(updateMasalahKulitMethod, { data, id, type });
  if (response.status === 200) {
    yield put(updateMasalahKulitFulfilled(response));
  }
  yield put(updateMasalahKulitReject(updateMasalahKulitReject(response)));
}
function* deleteMasalahKulit({ payload: id }) {
  const response = yield call(deleteMasalahKulitMethod, id);
  if (response.status === 200) {
    yield put(deleteMasalahKulitFulfilled(response));
  }
  yield put(deleteMasalahKulitReject(deleteMasalahKulitReject(response)));
}

export function* watchReadMasalahKulit() {
  yield takeLatest(READ_MASALAH_KULIT, readMasalahKulit);
}
export function* watchCreateMasalahKulit() {
  yield takeEvery(CREATE_MASALAH_KULIT, createMasalahKulit);
}
export function* watchUpdateMasalahKulit() {
  yield takeEvery(UPDATE_MASALAH_KULIT, updateMasalahKulit);
}
export function* watchDeleteMasalahKulit() {
  yield takeEvery(DELETE_MASALAH_KULIT, deleteMasalahKulit);
}

function* MasalahKulitSaga() {
  yield all([
    fork(watchReadMasalahKulit),
    fork(watchCreateMasalahKulit),
    fork(watchUpdateMasalahKulit),
    fork(watchDeleteMasalahKulit),
  ]);
}

export default MasalahKulitSaga;
