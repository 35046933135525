import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Modal,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { readUser, deleteUser } from "../../store/pages/user/actions";
import {
  readKondisiKulit,
} from "../../store/pages/kondisiKulit/actions";
import {
  readAlergiKulit,
} from "../../store/pages/alergiKulit/actions";
import {
  readMasalahKulit,
} from "../../store/pages/masalahKulit/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CSVLink } from "react-csv";
import { useHistory } from "react-router-dom";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import "../../assets/css/input.css";
import ChartistGraph from "react-chartist";
import useAsync from "@alifaishol/use-async";

const getAnalytics = async (kondisiKulits = [], alergiKulits = [], masalahKulits = []) => {
  const getUserCountByKondisi = async (kondisiKulit, type = 'kondisi_kulit') => {
    const response = await fetch(
      `${process.env.REACT_APP_API}/user?name=&${type}_id=${kondisiKulit}&limit=1&page=1`,
      {
        method: 'GET',
        mode: 'cors',
      }
    )
    return response.json().then(res => res.total_data)
  }

  return Promise.all([
    ...kondisiKulits.map(item => getUserCountByKondisi(item.id, 'kondisi_kulit')),
    ...alergiKulits.map(item => getUserCountByKondisi(item.id, 'alergi_kulit')),
    ...masalahKulits.map(item => getUserCountByKondisi(item.id, 'masalah_kulit')),
  ])
}

const User = (props) => {
  const user = props.user;
  const kondisi_kulit = props.kondisi_kulit;
  const alergi_kulit = props.alergi_kulit;
  const masalah_kulit = props.masalah_kulit;
  const first_page = props.first_page;
  const last_page = props.last_page;
  const active_page = props.active_page;
  const page_lists = props.page_lists;
  const next_page = props.next_page;
  const previous_page = props.previous_page;
  const start_index = props.start_index;
  const end_index = props.end_index;
  const history = useHistory();
  const {exec: getAnalyticsExec, state: getAnalyticsState} = useAsync(getAnalytics)

  const headers = [
    { label: "Nama User", key: "name" },
    { label: "Email", key: "email" },
    { label: "Tanggal Lahir", key: "birth_date" },
    { label: "Jenis Kelamin", key: "gender" },
    { label: "Kondisi Kulit", key: "kondisi_kulit" },
    { label: "Alergi Kulit", key: "alergi_kulit" },
    {
      label: "Kandungan Lainnya Pada Alergi Kulit",
      key: "alergi_kulit_user_description",
    },
    { label: "Masalah Kulit", key: "masalah_kulit" },
    { label: "Tanggal Menggunakan Skinadvisor", key: "date_created" },
  ];

  const data = user || "";
  const limit_list = [10, 25, 50, 100];
  const [modalDetail, setDetail] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [date, setDate] = useState("");
  const [search, setSearch] = useState("");
  const [selectedKondisiKulit, setSelectedKondisiKulit] = useState("");
  const [selectedAlergiKulit, setSelectedAlergiKulit] = useState("");
  const [selectedMasalahKulit, setSelectedMasalahKulit] = useState("");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  let today = new Date().toISOString().substr(0, 10);
  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const onChangeDate = (event) => {
    setDate({
      ...date,
      [event.target.name]: event.target.value,
    });
  };
  const onSearch = (event) => {
    props.readUser(date, event.target.value, selectedKondisiKulit, selectedAlergiKulit, selectedMasalahKulit);
    setSearch(event.target.value);
    history.push("/user-management");
  };
  const onPagination = (value) => {
    if (value.type === "Change Limit") {
      props.readUser(date, search, selectedKondisiKulit, selectedAlergiKulit, selectedMasalahKulit, value.limit, 1);
    } else {
      props.readUser(date, search, selectedKondisiKulit, selectedAlergiKulit, selectedMasalahKulit, value.limit, value.page);
    }
    setLimit(value.limit);
    setPage(value.page);
    history.push("/user-management");
  };

  const FirstPage = () => {
    if (first_page && first_page === active_page) {
      return (
        <PaginationItem
          onClick={() => onPagination({ limit: limit, page: first_page })}
          active
        >
          <PaginationLink>{first_page}</PaginationLink>
        </PaginationItem>
      );
    } else {
      return (
        <PaginationItem
          onClick={() => onPagination({ limit: limit, page: first_page })}
        >
          <PaginationLink>{first_page}</PaginationLink>
        </PaginationItem>
      );
    }
  };

  const LastPage = () => {
    if (last_page && last_page === active_page) {
      return (
        <PaginationItem
          onClick={() => onPagination({ limit: limit, page: last_page })}
          active
        >
          <PaginationLink>{last_page}</PaginationLink>
        </PaginationItem>
      );
    } else {
      return (
        <PaginationItem
          onClick={() => onPagination({ limit: limit, page: last_page })}
        >
          <PaginationLink>{last_page}</PaginationLink>
        </PaginationItem>
      );
    }
  };
  useEffect(() => {
    props.readKondisiKulit("");
    props.readAlergiKulit("");
    props.readMasalahKulit("");
    props.readUser(date, search, selectedKondisiKulit, selectedAlergiKulit, selectedMasalahKulit, limit, page);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (!kondisi_kulit || !alergi_kulit || !masalah_kulit) return;
    getAnalyticsExec(kondisi_kulit, alergi_kulit, masalah_kulit)
  }, [getAnalyticsExec, kondisi_kulit, alergi_kulit, masalah_kulit])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"User Management"}
            breadcrumbItem={"User Management"}
          />
          <Card>
            <CardBody>
              <CardTitle>User Management</CardTitle>
              {getAnalyticsState.data ? (
                <div>
                  <ChartistGraph
                    style={{height: '200px'}}
                    data={{labels: [
                      ...((kondisi_kulit && kondisi_kulit.map(item => item.name)) || []),
                      ...((alergi_kulit && alergi_kulit.map(item => item.name)) || []),
                      ...((masalah_kulit && masalah_kulit.map(item => item.name)) || [])
                    ], series: [getAnalyticsState.data ? getAnalyticsState.data : undefined]}}
                    type="Bar"
                  />
                </div>
              ) : (
                <div style={{height: 200, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                  <div className="spinner-chase">
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                  </div>
                </div>
              )}
              <div
                style={{
                  display: "grid",
                  gridAutoFlow: "column",
                  gridAutoColumns: "max-content",
                  columnGap: "8px",
                  justifyContent: "flex-start",
                }}
              >
                <input
                  placeholder="Search User"
                  onChange={onSearch}
                  style={{
                    borderRadius: "25px",
                    padding: "4px",
                    borderWidth: "1px",
                    borderColor: "gray",
                    borderStyle: "solid",
                    width: "250px",
                    height: "max-content",
                    alignSelf: "flex-end",
                    marginBottom: "1rem",
                  }}
                />
                <div className="form-group" style={{ display: "grid" }}>
                  <label
                    htmlFor="example-date-input"
                    className="col-form-label"
                    style={{ width: "max-content" }}
                  >
                    Show Data
                  </label>
                  <div>
                    <select
                      className="form-control"
                      defaultValue={limit}
                      onChange={(event) => {
                        onPagination({
                          limit: event.target.value,
                          page: page,
                          type: "Change Limit",
                        });
                      }}
                    >
                      {limit_list.map((value) => {
                        return (
                          <option
                            onClick={() => {
                              onPagination({
                                limit: value,
                                page: page,
                                type: "Change Limit",
                              });
                            }}
                          >
                            {value}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-group" style={{ display: "grid" }}>
                  <label
                    htmlFor="example-date-input"
                    className="col-form-label"
                    style={{ width: "max-content" }}
                  >
                    Start Date
                  </label>
                  <div>
                    <input
                      id="example-date-input"
                      type="date"
                      className="form-control text-align-center"
                      name="start"
                      max={today}
                      onChange={onChangeDate}
                    />
                  </div>
                </div>
                <div className="form-group" style={{ display: "grid" }}>
                  <label
                    htmlFor="example-date-input"
                    className="col-form-label"
                    style={{ width: "max-content" }}
                  >
                    End Date
                  </label>
                  <div>
                    <input
                      className="form-control"
                      type="date"
                      id="example-date-input"
                      name="end"
                      max={today}
                      min={date && date.start}
                      onChange={onChangeDate}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: "space-between",
                }}>
                <div style={{display: 'flex'}}>
                  <div className="form-group" style={{ display: "grid", marginRight: '8px' }}>
                    <label
                      htmlFor="example-date-input"
                      className="col-form-label"
                      style={{
                        width: "max-content",
                      }}
                    >
                      Kondisi Kulit
                    </label>
                    <div>
                      <select
                        className="form-control"
                        value={selectedKondisiKulit}
                        onChange={(event) => {
                          setSelectedKondisiKulit(event.target.value)
                        }}
                      >
                        <option value="">Semua</option>
                        {kondisi_kulit && kondisi_kulit.map((value) => (
                          <option value={value.id}>{value.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group" style={{ display: "grid", marginRight: '8px' }}>
                    <label
                      htmlFor="example-date-input"
                      className="col-form-label"
                      style={{
                        width: "max-content",
                        marginRight: "8px",
                      }}
                    >
                      Alergi Kulit
                    </label>
                    <div>
                      <select
                        className="form-control"
                        value={selectedAlergiKulit}
                        onChange={(event) => {
                          setSelectedAlergiKulit(event.target.value)
                        }}
                      >
                        <option value="">Semua</option>
                        {alergi_kulit && alergi_kulit.map((value) => (
                          <option value={value.id}>{value.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group" style={{ display: "grid", marginRight: '8px' }}>
                    <label
                      htmlFor="example-date-input"
                      className="col-form-label"
                      style={{
                        width: "max-content",
                        marginRight: "8px",
                      }}
                    >
                      Masalah Kulit
                    </label>
                    <div>
                      <select
                        className="form-control"
                        value={selectedMasalahKulit}
                        onChange={(event) => {
                          setSelectedMasalahKulit(event.target.value)
                        }}
                      >
                        <option value="">Semua</option>
                        {masalah_kulit && masalah_kulit.map((value) => (
                          <option value={value.id}>{value.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div style={{display: 'flex'}}>
                  <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    style={{
                      width: "max-content",
                      height: "max-content",
                      alignSelf: "flex-end",
                      marginBottom: "1rem",
                      marginRight: "8px",
                    }}
                    onClick={() => props.readUser(date, search, selectedKondisiKulit, selectedAlergiKulit, selectedMasalahKulit)}
                  >
                    <i className="bx bx-filter-alt font-size-16 align-middle mr-2"></i>{" "}
                    Filter
                  </button>
                  <CSVLink
                    data={data}
                    headers={headers}
                    style={{
                      width: "max-content",
                      height: "max-content",
                      alignSelf: "flex-end",
                      marginBottom: "1rem",
                    }}
                    separator={";"}
                    filename={"skinadvisor-users.csv"}
                  >
                    <button
                      type="button"
                      className="btn btn-success waves-effect waves-light"
                    >
                      <i className="bx bxs-file-export font-size-16 align-middle mr-2"></i>{" "}
                      Export
                    </button>
                  </CSVLink>
                </div>
              </div>
              <div className="table-responsive">
                <Table className="table table-centered">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Birth Date</th>
                      <th scope="col">Gender</th>
                      <th scope="col">Skinadvisor Using Date</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {user &&
                        user.map((value, index) => {
                          if (index >= start_index && index <= end_index) {
                            return (
                              <tr key={value.id}>
                                <th scope="row">
                                  <div>{index + 1}</div>
                                </th>
                                <td>{value.name}</td>
                                <td>{value.email}</td>
                                <td>{value.birth_date}</td>
                                <td>{value.gender}</td>
                                <td>{value.date_created}</td>
                                <td>
                                  <div
                                    style={{
                                      display: "grid",
                                      rowGap: "8px",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-info waves-effect waves-light"
                                      style={{ minWidth: "max-content" }}
                                      onClick={() => {
                                        setDetail(!modalDetail);
                                        setSelectedData(value);
                                      }}
                                    >
                                      <i className="bx bx-show-alt font-size-16 align-middle mr-2"></i>{" "}
                                      Detail
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-danger waves-effect waves-light"
                                      style={{ minWidth: "max-content" }}
                                      onClick={() => {
                                        setModalDelete(!modalDelete);
                                        setSelectedData(value);
                                      }}
                                    >
                                      <i className="bx bx-trash font-size-16 align-middle mr-2"></i>{" "}
                                      Delete
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                        })}
                  </tbody>
                </Table>
                {user && user.length <= 0 && (
                  <div style={{ textAlign: "center" }}>No Data</div>
                )}
              </div>
              <div
                style={{
                  display: "grid",
                  gridAutoFlow: "column",
                  justifyContent: "flex-end",
                }}
              >
                <Pagination aria-label="Page navigation example">
                  {previous_page && previous_page !== null ? (
                    <PaginationItem onClick={() => onPagination(previous_page)}>
                      <PaginationLink>Previous</PaginationLink>
                    </PaginationItem>
                  ) : (
                    <PaginationItem disabled>
                      <PaginationLink>Previous</PaginationLink>
                    </PaginationItem>
                  )}
                  <FirstPage />
                  {page_lists && page_lists[0] !== first_page + 1 && (
                    <PaginationItem>
                      <PaginationLink>...</PaginationLink>
                    </PaginationItem>
                  )}
                  {page_lists &&
                      page_lists.map((value) => {
                        if (parseInt(value) === parseInt(active_page)) {
                          return (
                            <PaginationItem
                              active
                              key={value}
                              onClick={() =>
                                  onPagination({ limit: limit, page: value })
                              }
                            >
                              <PaginationLink>{value}</PaginationLink>
                            </PaginationItem>
                          );
                        } else {
                          return (
                            <PaginationItem
                              key={value}
                              onClick={() =>
                                  onPagination({ limit: limit, page: value })
                              }
                            >
                              <PaginationLink>{value}</PaginationLink>
                            </PaginationItem>
                          );
                        }
                      })}
                  {page_lists && page_lists[page_lists.length - 1] !== last_page - 1 && (
                    <PaginationItem>
                      <PaginationLink>...</PaginationLink>
                    </PaginationItem>
                  )}
                  <LastPage />
                  {next_page && next_page !== null ? (
                    <PaginationItem onClick={() => onPagination(next_page)}>
                      <PaginationLink>Next</PaginationLink>
                    </PaginationItem>
                  ) : (
                    <PaginationItem disabled>
                      <PaginationLink>Next</PaginationLink>
                    </PaginationItem>
                  )}
                </Pagination>
              </div>
            </CardBody>
          </Card>

          {/* Modal Detail */}
          <Modal
            isOpen={modalDetail}
            toggle={() => {
              setDetail(!modalDetail);
              removeBodyCss();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Detail User
              </h5>
              <button
                type="button"
                onClick={() => {
                  setDetail(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h5>User Profile</h5>
              <div
                className="form-group"
                style={{
                }}
              >
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <div style={{whiteSpace: 'nowrap'}}>
                    <strong>Name :</strong>
                  </div>
                  &ensp;
                  <div>{selectedData && selectedData.name}</div>
                </div>
                {selectedData && selectedData.email &&(
                  <div style={{ alignItems: "center", display: "flex" }}>
                    <div>
                      <strong>Email :</strong>
                    </div>
                    &ensp;
                    <div>{selectedData.email}</div>
                  </div>
                )}
                <div style={{ alignItems: "center", display: "flex" }}>
                  <div>
                    <strong>Birth Date :</strong>
                  </div>
                  &ensp;
                  <div>{selectedData && selectedData.birth_date}</div>
                </div>
                <div style={{ alignItems: "center", display: "flex" }}>
                  <div>
                    <strong>Gender :</strong>
                  </div>
                  &ensp;
                  <div>{selectedData && selectedData.gender}</div>
                </div>
              </div>
              <h5>Kondisi Kulit User</h5>
              <div
                className="form-group"
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  columnGap: "8px",
                }}
              >
                <div className="form-group" style={{ alignItems: "center" }}>
                  <div>
                    <strong>Kondisi Kulit :</strong>
                  </div>
                  <div>
                    {selectedData &&
                        selectedData.kondisi_kulit.map((value, index) => {
                          return (
                            <div>
                              {index + 1}.&ensp;{value}
                            </div>
                          );
                        })}
                  </div>
                </div>
                <div className="form-group" style={{ alignItems: "center" }}>
                  <div>
                    <strong>Alergi Kulit :</strong>
                  </div>
                  <div>
                    {selectedData &&
                        selectedData.alergi_kulit.map((value, index) => {
                          return (
                            <div>
                              {index + 1}.&ensp;{value}
                            </div>
                          );
                        })}
                  </div>
                </div>
                <div className="form-group" style={{ alignItems: "center" }}>
                  <div>
                    <strong>Masalah Kulit :</strong>
                  </div>
                  <div>
                    {selectedData &&
                        selectedData.masalah_kulit.map((value, index) => {
                          return (
                            <div>
                              {index + 1}.&ensp;{value}
                            </div>
                          );
                        })}
                  </div>
                </div>
              </div>
              <div
                className="form-group"
                style={{ alignItems: "center", display: "flex" }}
              >
                <div>
                  <strong>Alergi Kulit Description :</strong>
                </div>
                &ensp;
                <div>
                  {selectedData && selectedData.alergi_kulit_user_description}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setDetail(!modalDetail);
                  removeBodyCss();
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </Modal>

          {/* Modal Delete */}
          <Modal
            isOpen={modalDelete}
            toggle={() => {
              setModalDelete(!modalDelete);
              removeBodyCss();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Delete User
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalDelete(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Are you sure want to delete this user?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setModalDelete(!modalDelete);
                  removeBodyCss();
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-danger waves-effect waves-light"
                onClick={() => {
                  props.deleteUser(selectedData.id);
                  setModalDelete(!modalDelete);
                  removeBodyCss();
                  setSelectedData(null);
                  setTimeout(() => {
                    alert("User Deleted Successfully!");
                  }, 400);
                }}
              >
                Delete
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const {
    user,
    message,
    loading,
    first_page,
    last_page,
    next_page,
    previous_page,
    start_index,
    end_index,
    page_lists,
    active_page,
  } = state.User;
  const { kondisi_kulit } = state.KondisiKulit;
  const { alergi_kulit } = state.AlergiKulit;
  const { masalah_kulit } = state.MasalahKulit;
  return {
    user,
    kondisi_kulit,
    alergi_kulit,
    masalah_kulit,
    message,
    loading,
    first_page,
    last_page,
    next_page,
    previous_page,
    start_index,
    end_index,
    page_lists,
    active_page,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      readKondisiKulit,
      readAlergiKulit,
      readMasalahKulit,
      readUser,
      deleteUser,
    },
    dispatch
  );

export default connect(mapStatetoProps, mapDispatchToProps)(User);
