export const READ_HOME_CARD = "READ_HOME_CARD";
export const READ_HOME_CARD_REJECT = "READ_HOME_CARD_REJECT";
export const READ_HOME_CARD_FULFILLED = "READ_HOME_CARD_FULFILLED";

export const CREATE_HOME_CARD = "CREATE_HOME_CARD";
export const CREATE_HOME_CARD_REJECT = "CREATE_HOME_CARD_REJECT";
export const CREATE_HOME_CARD_FULFILLED = "CREATE_HOME_CARD_FULFILLED";

export const UPDATE_HOME_CARD = "UPDATE_HOME_CARD";
export const UPDATE_HOME_CARD_REJECT = "UPDATE_HOME_CARD_REJECT";
export const UPDATE_HOME_CARD_FULFILLED = "UPDATE_HOME_CARD_FULFILLED";

export const DELETE_HOME_CARD = "DELETE_HOME_CARD";
export const DELETE_HOME_CARD_REJECT = "DELETE_HOME_CARD_REJECT";
export const DELETE_HOME_CARD_FULFILLED = "DELETE_HOME_CARD_FULFILLED";
