import {
  all,
  call,
  fork,
  put,
  takeLatest,
} from "redux-saga/effects";

import { READ_PRODUCT } from "./actionTypes";
import { readProductFulfilled, readProductReject } from "./actions";
import { readProductMethod } from "./method";

function* readProduct({ payload: search_data }) {
  const data = yield call(readProductMethod, search_data);
  if (data.status === "ok") {
    yield put(readProductFulfilled(data));
  }
  yield put(readProductReject(data));
}

export function* watchReadProduct() {
  yield takeLatest(READ_PRODUCT, readProduct);
}

function* ProductSaga() {
  yield all([fork(watchReadProduct)]);
}

export default ProductSaga;
