import {
  READ_PRODUCT,
  READ_PRODUCT_REJECT,
  READ_PRODUCT_FULFILLED,
} from "./actionTypes";

const INIT_STATE = {
  product: null,
  option_product: [],
};

const Product = (state = INIT_STATE, action) => {
  switch (action.type) {
    case READ_PRODUCT:
      return {
        ...state,
        lading: true,
      };
    case READ_PRODUCT_REJECT:
      return {
        ...state,
        lading: true,
      };
    case READ_PRODUCT_FULFILLED:
      if (state.option_product.length <= 0) {
        action.payload.data.map((value) => {
          return state.option_product.push({
            label: value.title,
            value: value.id,
          });
        });
      }
      return {
        ...state,
        product: action.payload.data,
        lading: false,
      };
    default:
      return state;
  }
};

export default Product;
