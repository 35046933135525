import React, { useEffect, useState } from "react";
import { Container, Card, CardBody, CardTitle, Table, Modal } from "reactstrap";
import {
  readHomeCard,
  createHomeCard,
  updateHomeCard,
  deleteHomeCard,
} from "../../store/pages/homeCard/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AvForm, AvField } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";

const HomeCard = (props) => {
  const home_card = props.home_card;

  const [modalCreate, setModalCreate] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [data, setData] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  const [imageUrl, setImageUrl] = useState(null);
  const [imageData, setImageData] = useState(null);

  const onInsertImage = async (event) => {
    const image = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImageUrl(reader.result);
        setImageData(image);
      }
    };
    reader.readAsDataURL(image);
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const onChangeData = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };
  const onChangeSelectedData = (event) => {
    setSelectedData({
      ...selectedData,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmitCreate = () => {
    let newData = new FormData();
    newData.append("description", data.description);
    newData.append("image", imageData);

    props.createHomeCard(newData);
    setModalCreate(!modalCreate);
    removeBodyCss();
    setData(null);
    setImageUrl(null);
    setImageData(null);
    setTimeout(() => {
      alert("Home Card Component Created Successfully!");
    }, 2000);
  };

  const onSubmitUpdate = () => {
    let newData = new FormData();
    newData.append("description", selectedData.description);
    newData.append("image", imageData);

    if (imageData === null) {
      props.updateHomeCard(selectedData, selectedData.id, "text");
    } else {
      props.updateHomeCard(newData, selectedData.id);
    }
    setModalUpdate(!modalUpdate);
    removeBodyCss();
    setSelectedData(null);
    setImageUrl(null);
    setImageData(null);
    setTimeout(() => {
      alert("Home Card Component Updated Successfully!");
    }, 2000);
  };
  console.log(data)

  const ButtonSubmitCreate = () => {
    if (data && Object.values(data).every((value) => value !== "") && imageData) {
      return (
        <button
          type="button"
          className="btn btn-primary waves-effect waves-light"
          onClick={onSubmitCreate}
        >
          Submit
        </button>
      );
    } else {
      return (
        <button
          type="button"
          className="btn btn-primary waves-effect waves-light"
          disabled
          style={{ cursor: "default" }}
        >
          Submit
        </button>
      );
    }
  };

  useEffect(() => {
    props.readHomeCard();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Home Card"} breadcrumbItem={"Home Card"} />
          <Card>
            <CardBody>
              <CardTitle>Home Card</CardTitle>
              <div
                className="col-md-12 mb-3"
                style={{ display: "grid", justifyItems: "flex-end" }}
              >
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => {
                    setModalCreate(!modalCreate);
                    setImageUrl(null);
                  }}
                >
                  <i className="bx bx-edit-alt font-size-16 align-middle mr-2"></i>{" "}
                  Insert
                </button>
              </div>
              <div className="table-responsive">
                <Table className="table table-centered">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Image</th>
                      <th scope="col">Description</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {home_card &&
                      home_card.map((value, index) => {
                        return (
                          <tr key={value.id}>
                            <th scope="row">
                              <div>{index + 1}</div>
                            </th>
                            <td>
                              <img
                                src={value.image}
                                alt="Home Card"
                                width="50px"
                              />
                            </td>
                            <td>{value.description}</td>
                            <td>
                              <div
                                style={{
                                  display: "grid",
                                  rowGap: "8px",
                                }}
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary waves-effect waves-light"
                                  style={{ minWidth: "max-content" }}
                                  onClick={() => {
                                    setModalUpdate(!modalUpdate);
                                    setSelectedData(value);
                                    setImageUrl(value.image);
                                  }}
                                >
                                  <i className="bx bx-edit font-size-16 align-middle mr-2"></i>{" "}
                                  Edit
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger waves-effect waves-light"
                                  style={{ minWidth: "max-content" }}
                                  onClick={() => {
                                    setModalDelete(!modalDelete);
                                    setSelectedData(value);
                                  }}
                                >
                                  <i className="bx bx-trash font-size-16 align-middle mr-2"></i>{" "}
                                  Delete
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
          {/* Modal Create */}
          <Modal
            isOpen={modalCreate}
            toggle={() => {
              setModalCreate(!modalCreate);
              removeBodyCss();
              setData(null);
              setImageData(null);
              setImageUrl(null);
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Insert Home Card Component
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalCreate(false);
                  setImageUrl(null);
                  setImageData(null);
                  setData(null);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <AvForm>
                <AvField
                  name="description"
                  label="Description"
                  value={data && data.description}
                  type="textarea"
                  errorMessage="Enter Description"
                  validate={{ required: { value: true } }}
                  onChange={onChangeData}
                />
                <label className="col-form-label">Image</label>
                <div
                  style={{
                    borderStyle: "dashed",
                    borderWidth: "2px",
                    borderColor: "primary",
                    borderRadius: "10px",
                    position: "relative",
                    minHeight: "250px",
                    display: "grid",
                    alignItems: "center",
                  }}
                >
                  {imageUrl === null ? (
                    <div
                      style={{
                        display: "grid",
                        justifyItems: "center",
                        fontSize: "24px",
                      }}
                    >
                      <i className="bx bx-cloud-upload align-middle"></i>
                      <div>Upload Image</div>
                    </div>
                  ) : (
                    <img
                      src={imageUrl}
                      style={{ width: "100%", borderRadius: "10px" }}
                      alt="HomeCard"
                    />
                  )}
                </div>
                <input
                  id="upload-home_card-image"
                  style={{ display: "none" }}
                  accept="image/*"
                  type="file"
                  onChange={onInsertImage}
                />
                <div className="text-center mt-4">
                  <label
                    type="button"
                    htmlFor="upload-home_card-image"
                    className="btn btn-primary waves-effect waves-light"
                  >
                    Upload File
                  </label>
                </div>
              </AvForm>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setModalCreate(!modalCreate);
                  removeBodyCss();
                  setImageUrl(null);
                  setImageData(null);
                  setData(null);
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              <ButtonSubmitCreate />
            </div>
          </Modal>

          {/* Modal Update */}
          <Modal
            isOpen={modalUpdate}
            toggle={() => {
              setModalUpdate(!modalUpdate);
              removeBodyCss();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Edit Home Card Component
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalUpdate(false);
                  setImageUrl(null);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <AvForm>
                <AvField
                  name="description"
                  label="Description"
                  type="textarea"
                  errorMessage="Enter Description"
                  validate={{ required: { value: true } }}
                  value={selectedData && selectedData.description}
                  onChange={onChangeSelectedData}
                />
                <label className="col-form-label">Image</label>
                <div
                  style={{
                    borderStyle: "dashed",
                    borderWidth: "2px",
                    borderColor: "primary",
                    borderRadius: "10px",
                    position: "relative",
                    minHeight: "250px",
                    display: "grid",
                    alignItems: "center",
                  }}
                >
                  {imageUrl === null ? (
                    <div
                      style={{
                        display: "grid",
                        justifyItems: "center",
                        fontSize: "24px",
                      }}
                    >
                      <i className="bx bx-cloud-upload align-middle"></i>
                      <div>Upload Image</div>
                    </div>
                  ) : (
                    <img
                      src={imageUrl}
                      style={{ width: "100%", borderRadius: "10px" }}
                      alt="HomeCard"
                    />
                  )}
                </div>
                <input
                  id="upload-home_card-image"
                  style={{ display: "none" }}
                  accept="image/*"
                  type="file"
                  onChange={onInsertImage}
                />
                <div className="text-center mt-4">
                  <label
                    type="button"
                    htmlFor="upload-home_card-image"
                    className="btn btn-primary waves-effect waves-light"
                  >
                    Upload Files
                  </label>
                </div>
              </AvForm>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setModalUpdate(!modalUpdate);
                  removeBodyCss();
                  setImageUrl(null);
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              {selectedData &&
              Object.values(selectedData).every((values) => values !== "") ? (
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={onSubmitUpdate}
                >
                  Submit
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  disabled
                >
                  Submit
                </button>
              )}
            </div>
          </Modal>

          {/* Modal Delete */}
          <Modal
            isOpen={modalDelete}
            toggle={() => {
              setModalDelete(!modalDelete);
              removeBodyCss();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Delete Home Card
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalDelete(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Are you sure want to delete this home card?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setModalDelete(!modalDelete);
                  removeBodyCss();
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-danger waves-effect waves-light"
                onClick={() => {
                  props.deleteHomeCard(selectedData.id);
                  setModalDelete(!modalDelete);
                  removeBodyCss();
                  setSelectedData(null);
                  setTimeout(() => {
                    alert("Home Card Component Deleted Successfully!");
                  }, 2000);
                }}
              >
                Delete
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { home_card, message, loading } = state.HomeCard;
  return { home_card, message, loading };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      readHomeCard,
      createHomeCard,
      updateHomeCard,
      deleteHomeCard,
    },
    dispatch
  );

export default connect(mapStatetoProps, mapDispatchToProps)(HomeCard);
