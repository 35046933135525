import {
  READ_PROFILE_ADMIN,
  READ_PROFILE_ADMIN_REJECT,
  READ_PROFILE_ADMIN_FULFILLED,
  UPDATE_PROFILE_ADMIN,
  UPDATE_PROFILE_ADMIN_REJECT,
  UPDATE_PROFILE_ADMIN_FULFILLED,
} from "./actionTypes";

const INIT_STATE = {
  admin: null,
  message: null,
  loading: false,
};

const ProfileAdmin = (state = INIT_STATE, action) => {
  switch (action.type) {
    case READ_PROFILE_ADMIN:
      return {
        ...state,
        lading: true,
      };
    case READ_PROFILE_ADMIN_REJECT:
      return {
        ...state,
        message: action.payload.message,
        lading: true,
      };
    case READ_PROFILE_ADMIN_FULFILLED:
      return {
        ...state,
        admin: action.payload.result,
        lading: false,
      };
    case UPDATE_PROFILE_ADMIN:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PROFILE_ADMIN_REJECT:
      return {
        ...state,
        message: action.payload.message,
        loading: true,
      };
    case UPDATE_PROFILE_ADMIN_FULFILLED:
      return {
        ...state,
        admin: action.payload.result,
        lading: false,
      };
    default:
      return state;
  }
};

export default ProfileAdmin;
