import {
  all,
  call,
  fork,
  takeEvery,
  put,
  takeLatest,
} from "redux-saga/effects";

import {
  CREATE_BASIC_INFO,
  READ_BASIC_INFO,
  UPDATE_BASIC_INFO,
  DELETE_BASIC_INFO,
} from "./actionTypes";
import {
  createBasicInfoReject,
  createBasicInfoFulfilled,
  readBasicInfoReject,
  readBasicInfoFulfilled,
  updateBasicInfoReject,
  updateBasicInfoFulfilled,
  deleteBasicInfoReject,
  deleteBasicInfoFulfilled,
} from "./actions";
import {
  createBasicInfoMethod,
  readBasicInfoMethod,
  updateBasicInfoMethod,
  deleteBasicInfoMethod,
} from "./method";

function* readBasicInfo() {
  const data = yield call(readBasicInfoMethod);
  if (data.status === 200) {
    yield put(readBasicInfoFulfilled(data));
  }
  yield put(readBasicInfoReject(data));
}
function* createBasicInfo({ payload: data }) {
  const response = yield call(createBasicInfoMethod, data);
  if (response.status === 200) {
    yield put(createBasicInfoFulfilled(response));
  }
  yield put(createBasicInfoReject(createBasicInfoReject(response)));
}
function* updateBasicInfo({ payload: { data, id, type } }) {
  const response = yield call(updateBasicInfoMethod, { data, id, type });
  if (response.status === 200) {
    yield put(updateBasicInfoFulfilled(response));
  }
  yield put(updateBasicInfoReject(updateBasicInfoReject(response)));
}
function* deleteBasicInfo({ payload: id }) {
  const response = yield call(deleteBasicInfoMethod, id);
  if (response.status === 200) {
    yield put(deleteBasicInfoFulfilled(response));
  }
  yield put(deleteBasicInfoReject(deleteBasicInfoReject(response)));
}

export function* watchReadBasicInfo() {
  yield takeLatest(READ_BASIC_INFO, readBasicInfo);
}
export function* watchCreateBasicInfo() {
  yield takeEvery(CREATE_BASIC_INFO, createBasicInfo);
}
export function* watchUpdateBasicInfo() {
  yield takeEvery(UPDATE_BASIC_INFO, updateBasicInfo);
}
export function* watchDeleteBasicInfo() {
  yield takeEvery(DELETE_BASIC_INFO, deleteBasicInfo);
}

function* BasicInfoSaga() {
  yield all([
    fork(watchReadBasicInfo),
    fork(watchCreateBasicInfo),
    fork(watchUpdateBasicInfo),
    fork(watchDeleteBasicInfo),
  ]);
}

export default BasicInfoSaga;
