import {
  CREATE_ALERGI_KULIT,
  CREATE_ALERGI_KULIT_REJECT,
  CREATE_ALERGI_KULIT_FULFILLED,
  READ_ALERGI_KULIT,
  READ_ALERGI_KULIT_REJECT,
  READ_ALERGI_KULIT_FULFILLED,
  UPDATE_ALERGI_KULIT,
  UPDATE_ALERGI_KULIT_REJECT,
  UPDATE_ALERGI_KULIT_FULFILLED,
  DELETE_ALERGI_KULIT,
  DELETE_ALERGI_KULIT_REJECT,
  DELETE_ALERGI_KULIT_FULFILLED,
} from "./actionTypes";

export const createAlergiKulit = (data) => {
  return {
    type: CREATE_ALERGI_KULIT,
    payload: data,
  };
};

export const createAlergiKulitReject = (payload) => {
  return {
    type: CREATE_ALERGI_KULIT_REJECT,
    payload: payload,
  };
};

export const createAlergiKulitFulfilled = (payload) => {
  return {
    type: CREATE_ALERGI_KULIT_FULFILLED,
    payload: payload,
  };
};

export const readAlergiKulit = (search_data) => {
  return {
    type: READ_ALERGI_KULIT,
    payload: search_data
  };
};

export const readAlergiKulitReject = (payload) => {
  return {
    type: READ_ALERGI_KULIT_REJECT,
    payload: payload,
  };
};

export const readAlergiKulitFulfilled = (data) => {
  return {
    type: READ_ALERGI_KULIT_FULFILLED,
    payload: data,
  };
};

export const updateAlergiKulit = (data, id) => {
  return {
    type: UPDATE_ALERGI_KULIT,
    payload: { data, id },
  };
};

export const updateAlergiKulitReject = (payload) => {
  return {
    type: UPDATE_ALERGI_KULIT_REJECT,
    payload: payload,
  };
};

export const updateAlergiKulitFulfilled = (payload) => {
  return {
    type: UPDATE_ALERGI_KULIT_FULFILLED,
    payload: payload,
  };
};

export const deleteAlergiKulit = (id) => {
  return {
    type: DELETE_ALERGI_KULIT,
    payload: id,
  };
};

export const deleteAlergiKulitReject = (payload) => {
  return {
    type: DELETE_ALERGI_KULIT_REJECT,
    payload: payload,
  };
};

export const deleteAlergiKulitFulfilled = (payload) => {
  return {
    type: DELETE_ALERGI_KULIT_FULFILLED,
    payload: payload,
  };
};
