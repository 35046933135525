import {
  READ_PROFILE_ADMIN,
  READ_PROFILE_ADMIN_REJECT,
  READ_PROFILE_ADMIN_FULFILLED,
  UPDATE_PROFILE_ADMIN,
  UPDATE_PROFILE_ADMIN_REJECT,
  UPDATE_PROFILE_ADMIN_FULFILLED,
} from "./actionTypes";

export const readProfileAdmin = () => {
  return {
    type: READ_PROFILE_ADMIN,
  };
};

export const readProfileAdminReject = (payload) => {
  return {
    type: READ_PROFILE_ADMIN_REJECT,
    payload: payload,
  };
};

export const readProfileAdminFulfilled = (data) => {
  return {
    type: READ_PROFILE_ADMIN_FULFILLED,
    payload: data,
  };
};

export const updateProfileAdmin = (data, id) => {
  return {
    type: UPDATE_PROFILE_ADMIN,
    payload: { data, id },
  };
};

export const updateProfileAdminReject = (payload) => {
  return {
    type: UPDATE_PROFILE_ADMIN_REJECT,
    payload: payload,
  };
};

export const updateProfileAdminFulfilled = (payload) => {
  return {
    type: UPDATE_PROFILE_ADMIN_FULFILLED,
    payload: payload,
  };
};