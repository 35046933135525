require("dotenv").config();

export const readProductMethod = async (search_data) => {
  if (search_data !== "") {
    const response = await fetch(
      `${process.env.REACT_APP_AVOSKIN_URL}/wp-json/avoskin/v1/get_product_list?total=100&search=${search_data}`,
      {
        method: "GET",
        mode: "cors",
      }
    );
    return response.json();
  } else {
    const response = await fetch(
      `${process.env.REACT_APP_AVOSKIN_URL}/wp-json/avoskin/v1/get_product_list?total=100`,
      {
        method: "GET",
        mode: "cors",
      }
    );
    return response.json();
  }
};
