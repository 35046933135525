import React, { useEffect, useState } from "react";
import { Container, Card, CardBody, CardTitle, Table, Modal } from "reactstrap";
import {
  readBasicInfo,
  createBasicInfo,
  updateBasicInfo,
  deleteBasicInfo,
} from "../../store/pages/basicInfo/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AvForm, AvField } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";

const BasicInfo = (props) => {
  const basic_info = props.basic_info;

  const [modalCreate, setModalCreate] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [data, setData] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  const [imageUrl, setImageUrl] = useState(null);
  const [imageData, setImageData] = useState(null);

  const onInsertImage = async (event) => {
    const image = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImageUrl(reader.result);
        setImageData(image);
      }
    };
    reader.readAsDataURL(image);
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const onChangeData = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };
  const onChangeSelectedData = (event) => {
    setSelectedData({
      ...selectedData,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmitCreate = () => {
    let newData = new FormData();
    newData.append("title", data.title);
    newData.append("subtitle", data.subtitle);
    newData.append("image", imageData);

    props.createBasicInfo(newData);
    setModalCreate(!modalCreate);
    removeBodyCss();
    setData(null);
  };

  const onSubmitUpdate = () => {
    let newData = new FormData();
    newData.append("title", selectedData.title);
    newData.append("subtitle", selectedData.subtitle);
    newData.append("image", imageData);

    if (imageData === null) {
      props.updateBasicInfo(selectedData, selectedData.id, "text");
    } else {
      props.updateBasicInfo(newData, selectedData.id);
    }
    setModalUpdate(!modalUpdate);
    removeBodyCss();
    setSelectedData(null);
    setTimeout(() => {
      alert("Basic Info Updated Successfully!")
    }, 400)
  };

  useEffect(() => {
    props.readBasicInfo();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Basic Info"} breadcrumbItem={"Basic Info"} />
          <Card>
            <CardBody>
              <CardTitle>Basic Info</CardTitle>
              <div
                className="col-md-12 mb-3"
                style={{ display: "grid", justifyItems: "flex-end" }}
              >
                {basic_info && basic_info.length <= 0 ? (
                  <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={() => setModalCreate(!modalCreate)}
                  >
                    <i className="bx bx-edit-alt font-size-16 align-middle mr-2"></i>{" "}
                    Insert
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={() => {
                      setModalUpdate(!modalUpdate);
                      setSelectedData(basic_info[0]);
                      setImageUrl(basic_info[0].image)
                    }}
                  >
                    <i className="bx bx-edit font-size-16 align-middle mr-2"></i>{" "}
                    Edit
                  </button>
                )}
              </div>
              {basic_info && basic_info.length > 0 && (
                <div className="table-responsive">
                  <Table className="table table-centered table-bordered">
                    <tbody>
                      <tr>
                        <th>Title</th>
                        <td>{basic_info && basic_info[0].title}</td>
                      </tr>
                      <tr>
                        <th>Subtitle</th>
                        <td>{basic_info && basic_info[0].subtitle}</td>
                      </tr>
                      <tr>
                        <th>Image</th>
                        <td>
                          <img
                            src={basic_info && basic_info[0].image}
                            alt="BasicInfo Image"
                            width="500px"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}
            </CardBody>
          </Card>
          {/* Modal Create */}
          <Modal
            isOpen={modalCreate}
            toggle={() => {
              setModalCreate(!modalCreate);
              removeBodyCss();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Insert Basic Info Component
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalCreate(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <AvForm>
                <AvField
                  name="title"
                  label="Title"
                  value={data && data.title}
                  placeholder="ex: Skin Advisor"
                  type="text"
                  errorMessage="Enter Title"
                  validate={{ required: { value: true } }}
                  onChange={onChangeData}
                />
                <AvField
                  name="subtitle"
                  label="Subtitle"
                  value={data && data.subtitle}
                  placeholder="ex: 	Skin Health is Number One"
                  type="text"
                  errorMessage="Enter Subtitle"
                  validate={{ required: { value: true } }}
                  onChange={onChangeData}
                />
                <label className="col-form-label">Image</label>
                <div
                  style={{
                    borderStyle: "dashed",
                    borderWidth: "2px",
                    borderColor: "primary",
                    borderRadius: "10px",
                    position: "relative",
                    minHeight: "250px",
                    display: "grid",
                    alignItems: "center",
                  }}
                >
                  {imageUrl === null ? (
                    <div
                      style={{
                        display: "grid",
                        justifyItems: "center",
                        fontSize: "24px",
                      }}
                    >
                      <i className="bx bx-cloud-upload align-middle"></i>
                      <div>Upload Image</div>
                    </div>
                  ) : (
                    <img
                      src={imageUrl}
                      style={{ width: "100%", borderRadius: "10px" }}
                      alt="BasicInfo"
                    />
                  )}
                </div>
                <input
                  id="upload-basic_info-image"
                  style={{ display: "none" }}
                  accept="image/*"
                  type="file"
                  onChange={onInsertImage}
                />
                <div className="text-center mt-4">
                  <label
                    type="button"
                    htmlFor="upload-basic_info-image"
                    className="btn btn-primary waves-effect waves-light"
                  >
                    Upload File
                  </label>
                </div>
              </AvForm>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setModalCreate(!modalCreate);
                  removeBodyCss();
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light"
                onClick={onSubmitCreate}
              >
                Submit
              </button>
            </div>
          </Modal>

          {/* Modal Update */}
          <Modal
            isOpen={modalUpdate}
            toggle={() => {
              setModalUpdate(!modalUpdate);
              removeBodyCss();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Edit Basic Info Component
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalUpdate(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <AvForm>
                <AvField
                  name="title"
                  label="Title"
                  value={selectedData && selectedData.title}
                  placeholder="ex: Skin Advisor"
                  type="text"
                  errorMessage="Enter Title"
                  validate={{ required: { value: true } }}
                  onChange={onChangeSelectedData}
                />
                <AvField
                  name="subtitle"
                  label="Subtitle"
                  value={selectedData && selectedData.subtitle}
                  placeholder="ex: Skin Health is Number One"
                  type="text"
                  errorMessage="Enter Subtitle"
                  validate={{ required: { value: true } }}
                  onChange={onChangeSelectedData}
                />
                <label className="col-form-label">Image</label>
                <div
                  style={{
                    borderStyle: "dashed",
                    borderWidth: "2px",
                    borderColor: "primary",
                    borderRadius: "10px",
                    position: "relative",
                    minHeight: "250px",
                    display: "grid",
                    alignItems: "center",
                  }}
                >
                  {imageUrl === null ? (
                    <div
                      style={{
                        display: "grid",
                        justifyItems: "center",
                        fontSize: "24px",
                      }}
                    >
                      <i className="bx bx-cloud-upload align-middle"></i>
                      <div>Upload Image</div>
                    </div>
                  ) : (
                    <img
                      src={imageUrl}
                      style={{ width: "100%", borderRadius: "10px" }}
                      alt="BasicInfo"
                    />
                  )}
                </div>
                <input
                  id="upload-basic_info-image"
                  style={{ display: "none" }}
                  accept="image/*"
                  type="file"
                  onChange={onInsertImage}
                />
                <div className="text-center mt-4">
                  <label
                    type="button"
                    htmlFor="upload-basic_info-image"
                    className="btn btn-primary waves-effect waves-light"
                  >
                    Upload Files
                  </label>
                </div>
              </AvForm>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setModalUpdate(!modalUpdate);
                  removeBodyCss();
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light"
                onClick={onSubmitUpdate}
              >
                Submit
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { basic_info, message, loading } = state.BasicInfo;
  return { basic_info, message, loading };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      readBasicInfo,
      createBasicInfo,
      updateBasicInfo,
      deleteBasicInfo,
    },
    dispatch
  );

export default connect(mapStatetoProps, mapDispatchToProps)(BasicInfo);
