export const READ_HOME = "READ_HOME";
export const READ_HOME_REJECT = "READ_HOME_REJECT";
export const READ_HOME_FULFILLED = "READ_HOME_FULFILLED";

export const CREATE_HOME = "CREATE_HOME";
export const CREATE_HOME_REJECT = "CREATE_HOME_REJECT";
export const CREATE_HOME_FULFILLED = "CREATE_HOME_FULFILLED";

export const UPDATE_HOME = "UPDATE_HOME";
export const UPDATE_HOME_REJECT = "UPDATE_HOME_REJECT";
export const UPDATE_HOME_FULFILLED = "UPDATE_HOME_FULFILLED";

export const DELETE_HOME = "DELETE_HOME";
export const DELETE_HOME_REJECT = "DELETE_HOME_REJECT";
export const DELETE_HOME_FULFILLED = "DELETE_HOME_FULFILLED";
