import {
  CREATE_HOME,
  CREATE_HOME_REJECT,
  CREATE_HOME_FULFILLED,
  READ_HOME,
  READ_HOME_REJECT,
  READ_HOME_FULFILLED,
  UPDATE_HOME,
  UPDATE_HOME_REJECT,
  UPDATE_HOME_FULFILLED,
  DELETE_HOME,
  DELETE_HOME_REJECT,
  DELETE_HOME_FULFILLED,
} from "./actionTypes";

export const createHome = (data) => {
  return {
    type: CREATE_HOME,
    payload: data,
  };
};

export const createHomeReject = (payload) => {
  return {
    type: CREATE_HOME_REJECT,
    payload: payload,
  };
};

export const createHomeFulfilled = (payload) => {
  return {
    type: CREATE_HOME_FULFILLED,
    payload: payload,
  };
};

export const readHome = () => {
  return {
    type: READ_HOME,
  };
};

export const readHomeReject = (payload) => {
  return {
    type: READ_HOME_REJECT,
    payload: payload,
  };
};

export const readHomeFulfilled = (data) => {
  return {
    type: READ_HOME_FULFILLED,
    payload: data,
  };
};

export const updateHome = (data, id, type) => {
  return {
    type: UPDATE_HOME,
    payload: { data, id, type },
  };
};

export const updateHomeReject = (payload) => {
  return {
    type: UPDATE_HOME_REJECT,
    payload: payload,
  };
};

export const updateHomeFulfilled = (payload) => {
  return {
    type: UPDATE_HOME_FULFILLED,
    payload: payload,
  };
};

export const deleteHome = (id) => {
  return {
    type: DELETE_HOME,
    payload: id,
  };
};

export const deleteHomeReject = (payload) => {
  return {
    type: DELETE_HOME_REJECT,
    payload: payload,
  };
};

export const deleteHomeFulfilled = (payload) => {
  return {
    type: DELETE_HOME_FULFILLED,
    payload: payload,
  };
};
