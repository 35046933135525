import {
  all,
  call,
  fork,
  takeEvery,
  put,
  takeLatest,
} from "redux-saga/effects";

import {
  CREATE_INFO_KONDISI_KULIT,
  READ_INFO_KONDISI_KULIT,
  UPDATE_INFO_KONDISI_KULIT,
  DELETE_INFO_KONDISI_KULIT,
} from "./actionTypes";
import {
  createInfoKondisiKulitReject,
  createInfoKondisiKulitFulfilled,
  readInfoKondisiKulitReject,
  readInfoKondisiKulitFulfilled,
  updateInfoKondisiKulitReject,
  updateInfoKondisiKulitFulfilled,
  deleteInfoKondisiKulitReject,
  deleteInfoKondisiKulitFulfilled,
} from "./actions";
import {
  createInfoKondisiKulitMethod,
  readInfoKondisiKulitMethod,
  updateInfoKondisiKulitMethod,
  deleteInfoKondisiKulitMethod,
} from "./method";

function* readInfoKondisiKulit() {
  const data = yield call(readInfoKondisiKulitMethod);
  if (data.status === 200) {
    yield put(readInfoKondisiKulitFulfilled(data));
  }
  yield put(readInfoKondisiKulitReject(data));
}
function* createInfoKondisiKulit({ payload: data }) {
  const response = yield call(createInfoKondisiKulitMethod, data);
  if (response.status === 200) {
    yield put(createInfoKondisiKulitFulfilled(response));
  }
  yield put(createInfoKondisiKulitReject(createInfoKondisiKulitReject(response)));
}
function* updateInfoKondisiKulit({ payload: { data, id } }) {
  const response = yield call(updateInfoKondisiKulitMethod, { data, id });
  if (response.status === 200) {
    yield put(updateInfoKondisiKulitFulfilled(response));
  }
  yield put(updateInfoKondisiKulitReject(updateInfoKondisiKulitReject(response)));
}
function* deleteInfoKondisiKulit({ payload: id }) {
  const response = yield call(deleteInfoKondisiKulitMethod, id);
  if (response.status === 200) {
    yield put(deleteInfoKondisiKulitFulfilled(response));
  }
  yield put(deleteInfoKondisiKulitReject(deleteInfoKondisiKulitReject(response)));
}

export function* watchReadInfoKondisiKulit() {
  yield takeLatest(READ_INFO_KONDISI_KULIT, readInfoKondisiKulit);
}
export function* watchCreateInfoKondisiKulit() {
  yield takeEvery(CREATE_INFO_KONDISI_KULIT, createInfoKondisiKulit);
}
export function* watchUpdateInfoKondisiKulit() {
  yield takeEvery(UPDATE_INFO_KONDISI_KULIT, updateInfoKondisiKulit);
}
export function* watchDeleteInfoKondisiKulit() {
  yield takeEvery(DELETE_INFO_KONDISI_KULIT, deleteInfoKondisiKulit);
}

function* InfoKondisiKulitSaga() {
  yield all([
    fork(watchReadInfoKondisiKulit),
    fork(watchCreateInfoKondisiKulit),
    fork(watchUpdateInfoKondisiKulit),
    fork(watchDeleteInfoKondisiKulit),
  ]);
}

export default InfoKondisiKulitSaga;
