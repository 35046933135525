export const READ_INFO_KONDISI_KULIT = "READ_INFO_KONDISI_KULIT";
export const READ_INFO_KONDISI_KULIT_REJECT = "READ_INFO_KONDISI_KULIT_REJECT";
export const READ_INFO_KONDISI_KULIT_FULFILLED = "READ_INFO_KONDISI_KULIT_FULFILLED";

export const CREATE_INFO_KONDISI_KULIT = "CREATE_INFO_KONDISI_KULIT";
export const CREATE_INFO_KONDISI_KULIT_REJECT = "CREATE_INFO_KONDISI_KULIT_REJECT";
export const CREATE_INFO_KONDISI_KULIT_FULFILLED = "CREATE_INFO_KONDISI_KULIT_FULFILLED";

export const UPDATE_INFO_KONDISI_KULIT = "UPDATE_INFO_KONDISI_KULIT";
export const UPDATE_INFO_KONDISI_KULIT_REJECT = "UPDATE_INFO_KONDISI_KULIT_REJECT";
export const UPDATE_INFO_KONDISI_KULIT_FULFILLED = "UPDATE_INFO_KONDISI_KULIT_FULFILLED";

export const DELETE_INFO_KONDISI_KULIT = "DELETE_INFO_KONDISI_KULIT";
export const DELETE_INFO_KONDISI_KULIT_REJECT = "DELETE_INFO_KONDISI_KULIT_REJECT";
export const DELETE_INFO_KONDISI_KULIT_FULFILLED = "DELETE_INFO_KONDISI_KULIT_FULFILLED";
