import React, { useEffect, useState } from "react";
import { Container, Card, CardBody, CardTitle, Table, Modal } from "reactstrap";
import {
  readInfoKondisiKulit,
  createInfoKondisiKulit,
  updateInfoKondisiKulit,
  deleteInfoKondisiKulit,
} from "../../store/pages/infoKondisiKulit/actions";
import {
  readInfoKondisiKulitImage,
  createInfoKondisiKulitImage,
  updateInfoKondisiKulitImage,
  deleteInfoKondisiKulitImage,
} from "../../store/pages/infoKondisiKulitImage/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AvForm, AvField } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";

const InfoKondisiKulit = (props) => {
  const info_kondisi_kulit = props.info_kondisi_kulit;
  const info_kondisi_kulit_image = props.info_kondisi_kulit_image;

  const [modalCreate, setModalCreate] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [data, setData] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  const [modalCreateImage, setModalCreateImage] = useState(false);
  const [modalUpdateImage, setModalUpdateImage] = useState(false);
  const [modalDeleteImage, setModalDeleteImage] = useState(false);

  const [imageUrl, setImageUrl] = useState(null);
  const [imageData, setImageData] = useState(null);

  const onInsertImage = async (event) => {
    const image = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImageUrl(reader.result);
        setImageData(image);
      }
    };
    reader.readAsDataURL(image);
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const onChangeData = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };
  const onChangeSelectedData = (event) => {
    setSelectedData({
      ...selectedData,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmitCreate = () => {
    props.createInfoKondisiKulit(data);
    setModalCreate(!modalCreate);
    removeBodyCss();
    setData(null);
    setTimeout(() => {
      alert("Info Kondisi Kulit Created Successfully!");
    }, 2000);
  };

  const onSubmitUpdate = () => {
    props.updateInfoKondisiKulit(selectedData, selectedData.id);
    setModalUpdate(!modalUpdate);
    removeBodyCss();
    setSelectedData(null);
    setTimeout(() => {
      alert("Info Kondisi Kulit Updated Successfully!");
    }, 2000);
  };

  const onSubmitCreateImage = () => {
    let newData = new FormData();
    newData.append("image", imageData);
    newData.append("id_info_kondisi_kulit", info_kondisi_kulit[0].id);

    props.createInfoKondisiKulitImage(newData);
    setModalCreateImage(!modalCreateImage);
    removeBodyCss();
    setImageData(null);
    setImageUrl(null);
    setTimeout(() => {
      alert("Image Created Successfully!");
    }, 2000);
  };

  const onSubmitUpdateImage = () => {
    let newData = new FormData();
    newData.append("image", imageData);

    props.updateInfoKondisiKulitImage(newData, selectedData);
    setModalUpdateImage(!modalUpdateImage);
    removeBodyCss();
    setSelectedData(null);
    setImageData(null);
    setImageUrl(null);
    setTimeout(() => {
      alert("Image Updated Successfully!");
    }, 2000);
  };

  useEffect(() => {
    props.readInfoKondisiKulit();
    props.readInfoKondisiKulitImage();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Info Kondisi Kulit"}
            breadcrumbItem={"Info Kondisi Kulit"}
          />
          <Card>
            <CardBody>
              <CardTitle>Info Kondisi Kulit</CardTitle>
              <div
                className="col-md-12 mb-3"
                style={{ display: "grid", justifyItems: "flex-end" }}
              >
                {info_kondisi_kulit && info_kondisi_kulit.length <= 0 ? (
                  <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={() => setModalCreate(!modalCreate)}
                  >
                    <i className="bx bx-edit-alt font-size-16 align-middle mr-2"></i>{" "}
                    Insert
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={() => {
                      setModalUpdate(!modalUpdate);
                      setSelectedData(info_kondisi_kulit[0]);
                      setImageUrl(info_kondisi_kulit[0].image);
                    }}
                  >
                    <i className="bx bx-edit font-size-16 align-middle mr-2"></i>{" "}
                    Edit
                  </button>
                )}
              </div>
              {info_kondisi_kulit && info_kondisi_kulit.length > 0 && (
                <div className="table-responsive">
                  <Table className="table table-centered table-bordered">
                    <tbody>
                      <tr>
                        <th>Title</th>
                        <td>
                          {info_kondisi_kulit && info_kondisi_kulit[0].title}
                        </td>
                      </tr>
                      <tr>
                        <th>Subtitle</th>
                        <td>
                          {info_kondisi_kulit && info_kondisi_kulit[0].subtitle}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}
            </CardBody>
          </Card>
          {/* Modal Create */}
          <Modal
            isOpen={modalCreate}
            toggle={() => {
              setModalCreate(!modalCreate);
              removeBodyCss();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Insert Info Kondisi Kulit Component
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalCreate(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <AvForm>
                <AvField
                  name="title"
                  label="Title"
                  value={data && data.title}
                  placeholder="ex: Skin Advisor"
                  type="text"
                  errorMessage="Enter Title"
                  validate={{ required: { value: true } }}
                  onChange={onChangeData}
                />
                <AvField
                  name="subtitle"
                  label="Subtitle"
                  value={data && data.subtitle}
                  placeholder="ex: 	Skin Health is Number One"
                  type="text"
                  errorMessage="Enter Subtitle"
                  validate={{ required: { value: true } }}
                  onChange={onChangeData}
                />
              </AvForm>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setModalCreate(!modalCreate);
                  removeBodyCss();
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light"
                onClick={onSubmitCreate}
              >
                Submit
              </button>
            </div>
          </Modal>

          {/* Modal Update */}
          <Modal
            isOpen={modalUpdate}
            toggle={() => {
              setModalUpdate(!modalUpdate);
              removeBodyCss();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Edit Info Kondisi Kulit Component
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalUpdate(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <AvForm>
                <AvField
                  name="title"
                  label="Title"
                  value={selectedData && selectedData.title}
                  placeholder="ex: Skin Advisor"
                  type="text"
                  errorMessage="Enter Title"
                  validate={{ required: { value: true } }}
                  onChange={onChangeSelectedData}
                />
                <AvField
                  name="subtitle"
                  label="Subtitle"
                  value={selectedData && selectedData.subtitle}
                  placeholder="ex: Skin Health is Number One"
                  type="text"
                  errorMessage="Enter Subtitle"
                  validate={{ required: { value: true } }}
                  onChange={onChangeSelectedData}
                />
              </AvForm>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setModalUpdate(!modalUpdate);
                  removeBodyCss();
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light"
                onClick={onSubmitUpdate}
              >
                Submit
              </button>
            </div>
          </Modal>
          <Card>
            <CardBody>
              <CardTitle>Info Kondisi Kulit Image</CardTitle>
              <div
                className="col-md-12 mb-3"
                style={{ display: "grid", justifyItems: "flex-end" }}
              >
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => {
                    setModalCreateImage(!modalCreateImage);
                    setImageUrl(null);
                  }}
                >
                  <i className="bx bx-edit-alt font-size-16 align-middle mr-2"></i>{" "}
                  Insert
                </button>
              </div>
              {info_kondisi_kulit_image && info_kondisi_kulit_image.length > 0 && (
                <div className="table-responsive">
                  <Table className="table table-centered">
                    <thead>
                      <tr>
                        <th scope="col">No</th>
                        <th scope="col">Image</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {info_kondisi_kulit_image[0].image[0].image_id &&
                        info_kondisi_kulit_image[0].image.map(
                          (value, index) => {
                            return (
                              <tr key={value.id}>
                                <th scope="row">
                                  <div>{index + 1}</div>
                                </th>
                                <td>
                                  <img
                                    src={value.image}
                                    alt="Info Kulit"
                                    width="500px"
                                  />
                                </td>
                                <td>
                                  <div
                                    style={{
                                      display: "grid",
                                      rowGap: "8px",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-primary waves-effect waves-light"
                                      style={{ minWidth: "max-content" }}
                                      onClick={() => {
                                        setModalUpdateImage(!modalUpdateImage);
                                        setSelectedData(value.image_id);
                                        setImageUrl(value.image);
                                      }}
                                    >
                                      <i className="bx bx-edit font-size-16 align-middle mr-2"></i>{" "}
                                      Edit
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-danger waves-effect waves-light"
                                      style={{ minWidth: "max-content" }}
                                      onClick={() => {
                                        setModalDeleteImage(!modalDeleteImage);
                                        setSelectedData(value.image_id);
                                      }}
                                    >
                                      <i className="bx bx-trash font-size-16 align-middle mr-2"></i>{" "}
                                      Delete
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </Table>
                </div>
              )}
            </CardBody>
          </Card>
          {/* Modal Create */}
          <Modal
            isOpen={modalCreateImage}
            toggle={() => {
              setModalCreateImage(!modalCreateImage);
              removeBodyCss();
              setImageData(null);
              setImageUrl(null);
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Insert Info Kondisi Kulit Image
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalCreateImage(false);
                  setImageData(null);
                  setImageUrl(null);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <AvForm>
                <label className="col-form-label">Image</label>
                <div
                  style={{
                    borderStyle: "dashed",
                    borderWidth: "2px",
                    borderColor: "primary",
                    borderRadius: "10px",
                    position: "relative",
                    minHeight: "250px",
                    display: "grid",
                    alignItems: "center",
                  }}
                >
                  {imageUrl === null ? (
                    <div
                      style={{
                        display: "grid",
                        justifyItems: "center",
                        fontSize: "24px",
                      }}
                    >
                      <i className="bx bx-cloud-upload align-middle"></i>
                      <div>Upload Image</div>
                    </div>
                  ) : (
                    <img
                      src={imageUrl}
                      style={{ width: "100%", borderRadius: "10px" }}
                      alt="Info Kondisi Kulit"
                    />
                  )}
                </div>
                <input
                  id="upload-home_card-image"
                  style={{ display: "none" }}
                  accept="image/*"
                  type="file"
                  onChange={onInsertImage}
                />
                <div className="text-center mt-4">
                  <label
                    type="button"
                    htmlFor="upload-home_card-image"
                    className="btn btn-primary waves-effect waves-light"
                  >
                    Upload File
                  </label>
                </div>
              </AvForm>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setModalCreateImage(!modalCreateImage);
                  removeBodyCss();
                  setImageData(null);
                  setImageUrl(null);
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              {imageData === null ? (
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  disabled
                >
                  Submit
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={onSubmitCreateImage}
                >
                  Submit
                </button>
              )}
            </div>
          </Modal>

          {/* Modal Update */}
          <Modal
            isOpen={modalUpdateImage}
            toggle={() => {
              setModalUpdateImage(!modalUpdateImage);
              removeBodyCss();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Edit Info Kondisi Kulit Image
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalUpdateImage(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <AvForm>
                <label className="col-form-label">Image</label>
                <div
                  style={{
                    borderStyle: "dashed",
                    borderWidth: "2px",
                    borderColor: "primary",
                    borderRadius: "10px",
                    position: "relative",
                    minHeight: "250px",
                    display: "grid",
                    alignItems: "center",
                  }}
                >
                  {imageUrl === null ? (
                    <div
                      style={{
                        display: "grid",
                        justifyItems: "center",
                        fontSize: "24px",
                      }}
                    >
                      <i className="bx bx-cloud-upload align-middle"></i>
                      <div>Upload Image</div>
                    </div>
                  ) : (
                    <img
                      src={imageUrl}
                      style={{ width: "100%", borderRadius: "10px" }}
                      alt="HomeCard"
                    />
                  )}
                </div>
                <input
                  id="upload-home_card-image"
                  style={{ display: "none" }}
                  accept="image/*"
                  type="file"
                  onChange={onInsertImage}
                />
                <div className="text-center mt-4">
                  <label
                    type="button"
                    htmlFor="upload-home_card-image"
                    className="btn btn-primary waves-effect waves-light"
                  >
                    Upload Files
                  </label>
                </div>
              </AvForm>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setModalUpdateImage(!modalUpdateImage);
                  removeBodyCss();
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light"
                onClick={onSubmitUpdateImage}
              >
                Submit
              </button>
            </div>
          </Modal>

          {/* Modal Delete */}
          <Modal
            isOpen={modalDeleteImage}
            toggle={() => {
              setModalDeleteImage(!modalDeleteImage);
              removeBodyCss();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Delete Info Kondisi Kulit Image
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalDeleteImage(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Are you sure want to delete this image?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setModalDeleteImage(!modalDeleteImage);
                  removeBodyCss();
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-danger waves-effect waves-light"
                onClick={() => {
                  props.deleteInfoKondisiKulitImage(selectedData);
                  setModalDeleteImage(!modalDeleteImage);
                  removeBodyCss();
                  setSelectedData(null);
                  setTimeout(() => {
                    alert("Image Deleted Successfully!");
                  }, 2000);
                }}
              >
                Delete
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { info_kondisi_kulit, message, loading } = state.InfoKondisiKulit;
  const { info_kondisi_kulit_image } = state.InfoKondisiKulitImage;
  return { info_kondisi_kulit, message, loading, info_kondisi_kulit_image };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      readInfoKondisiKulit,
      createInfoKondisiKulit,
      updateInfoKondisiKulit,
      deleteInfoKondisiKulit,
      readInfoKondisiKulitImage,
      createInfoKondisiKulitImage,
      updateInfoKondisiKulitImage,
      deleteInfoKondisiKulitImage,
    },
    dispatch
  );

export default connect(mapStatetoProps, mapDispatchToProps)(InfoKondisiKulit);
