import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Modal,
  Table,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import {
  readAlergiProduct,
  createAlergiProduct,
  updateAlergiProduct,
  deleteAlergiProduct,
} from "../../store/pages/alergiProduct/actions";
import { readAlergiKulit } from "../../store/pages/alergiKulit/actions";
import { readProduct } from "../../store/pages/product/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router-dom";

import Breadcrumbs from "../../components/Common/Breadcrumb";

const AlergiProduct = (props) => {
  const alergi_product = props.alergi_product;
  const alergi_kulit = props.alergi_kulit;
  const option_product = props.option_product;
  const all_product = props.product;
  const history = useHistory();

  const [modalCreate, setModalCreate] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [selectedMulti, setSelectedMulti] = useState(null);

  const [id_alergi_kulit, setIdAlergiKulit] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const handleMulti = (selectedMulti) => {
    setSelectedMulti(selectedMulti);
  };

  const handleEdit = (value) => {
    setSelectedData(value);
    setModalUpdate(!modalUpdate);
  };

  const onSubmitCreate = async () => {
    let id_product = [];
    await selectedMulti.map((value) => {
      return id_product.push(value.value);
    });
    let checkAlergi = await alergi_kulit.filter((value) => {
      return value.id === id_alergi_kulit;
    });
    let checkProduct = checkAlergi[0].product.filter((value) => {
      return id_product.indexOf(value.id) !== -1;
    });
    if (checkProduct.length === 0) {
      props.createAlergiProduct({
        id_alergi_kulit: id_alergi_kulit,
        id_product: id_product,
      });
      setModalCreate(!modalCreate);
      removeBodyCss();
      setIdAlergiKulit(null);
      setSelectedMulti(null);
      setTimeout(() => {
        alert("Alergi Product Created Successfully!");
      }, 2000);
    } else {
      alert("Product has already added before!");
    }
  };
  const onSubmitEdit = async () => {
    let checkAlergi = await alergi_kulit.filter((value) => {
      return value.id === selectedData.id_alergi_kulit;
    });
    let checkProduct = await checkAlergi[0].id_product.filter(
      (value) => value === selectedData.id_product
    );
    if (checkProduct.length === 0) {
      props.updateAlergiProduct(
        {
          id_product: selectedData.id_product,
        },
        selectedData.id
      );
      setModalUpdate(!modalUpdate);
      removeBodyCss();
      setSelectedData(null);
      setSelectedMulti(null);
      setTimeout(() => {
        alert("Alergi Product Updated Successfully!");
      }, 2000);
    } else {
      alert("This product has already added before!");
    }
  };
  const onSearch = (event) => {
    props.readAlergiProduct(event.target.value);
    history.push("/alergi-product");
  };

  useEffect(() => {
    props.readProduct("");
    props.readAlergiProduct("");
    props.readAlergiKulit("");
  }, []);

  console.log(alergi_product)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Product List"}
            breadcrumbItem={"Alergi Product"}
          />
          <Card>
            <CardBody>
              <CardTitle>Alergi Product</CardTitle>
              <div
                className="col-md-12 mb-3"
                style={{
                  display: "grid",
                  justifyItems: "flex-end",
                  gridTemplateColumns: "1fr max-content",
                  columnGap: "8px",
                }}
              >
                <input
                  placeholder="Search Alergi Product"
                  onChange={onSearch}
                  style={{
                    borderRadius: "25px",
                    padding: "4px",
                    borderWidth: "1px",
                    borderColor: "gray",
                    borderStyle: "solid",
                    width: "250px",
                  }}
                />
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => setModalCreate(!modalCreate)}
                >
                  <i className="bx bx-edit-alt font-size-16 align-middle mr-2"></i>{" "}
                  Insert
                </button>
              </div>
              <div className="table-responsive">
                <Table className="table table-centered">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Alergi Kulit</th>
                      <th scope="col">Product Image</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">Stock</th>
                      <th scope="col">Price</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {alergi_product &&
                      alergi_product.map((value, index) => {
                        return (
                          <tr key={value.alergi_kulit_id}>
                            <th scope="row">
                              <div>{index + 1}</div>
                            </th>
                            <td>{value.alergi_kulit_name}</td>
                            <td>
                              <img
                                width="50px"
                                src={value.product.thumbnail}
                                alt={value.product.title}
                              ></img>
                            </td>
                            <td>{value.product.title}</td>
                            <td>{value.product.stock}</td>
                            <td>{value.product.price}</td>
                            <td>
                              <div
                                style={{
                                  display: "grid",
                                  rowGap: "8px",
                                }}
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary waves-effect waves-light"
                                  style={{ minWidth: "max-content" }}
                                  onClick={() => {
                                    handleEdit(value);
                                  }}
                                >
                                  <i className="bx bx-edit font-size-16 align-middle mr-2"></i>{" "}
                                  Edit
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger waves-effect waves-light"
                                  style={{ minWidth: "max-content" }}
                                  onClick={() => {
                                    setModalDelete(!modalDelete);
                                    setSelectedData(value);
                                  }}
                                >
                                  <i className="bx bx-trash font-size-16 align-middle mr-2"></i>{" "}
                                  Delete
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>

          {/* Modal Create */}
          <Modal
            isOpen={modalCreate}
            toggle={() => {
              setModalCreate(!modalCreate);
              removeBodyCss();
              setIdAlergiKulit(null);
              setSelectedMulti(null);
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Insert Alergi Product
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalCreate(false);
                  setIdAlergiKulit(null);
                  setSelectedMulti(null);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label className="col-form-label">Alergi Kulit</label>
                <div>
                  <select
                    name="id_alergi_kulit"
                    className="form-control"
                    defaultValue="default"
                    onChange={(event) => {
                      setIdAlergiKulit(event.target.value);
                    }}
                  >
                    <option value="default" disabled>
                      Select Alergi Kulit
                    </option>
                    {alergi_kulit &&
                      alergi_kulit.map((value) => {
                        return (
                          <option
                            key={value.id}
                            value={value.id}
                            onClick={(event) => {
                              setIdAlergiKulit(event.target.value);
                            }}
                          >
                            {value.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <FormGroup className="select2-container">
                <label className="control-label">Alergi Product</label>
                <Select
                  isMulti={true}
                  onChange={(...args) => {
                    handleMulti(...args);
                  }}
                  options={option_product}
                  classNamePrefix="select2-selection height-max-content"
                />
              </FormGroup>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setModalCreate(!modalCreate);
                  removeBodyCss();
                  setIdAlergiKulit(null);
                  setSelectedMulti(null);
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              {id_alergi_kulit === null ? (
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  disabled
                >
                  Submit
                </button>
              ) : selectedMulti === null ? (
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  disabled
                >
                  Submit
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => onSubmitCreate()}
                >
                  Submit
                </button>
              )}
            </div>
          </Modal>

          {/* Modal Update */}
          <Modal
            isOpen={modalUpdate}
            toggle={() => {
              setModalUpdate(!modalUpdate);
              removeBodyCss();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Edit Alergi Product
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalUpdate(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label className="col-form-label">Alergi Kulit</label>
                <div>
                  <select
                    name="id_alergi_kulit"
                    className="form-control text-muted disabled"
                    style={{backgroundColor:"#ced4da"}}
                    defaultValue={selectedData && selectedData.id_alergi_kulit}
                    disabled
                  >
                    <option
                      value={selectedData && selectedData.id_alergi_kulit}
                      disabled
                    >
                      {selectedData && selectedData.alergi_kulit_name}
                    </option>
                  </select>
                </div>
              </div>
              <FormGroup className="select2-container">
                <label className="control-label">Alergi Product</label>
                <div>
                  <select
                    name="id_product"
                    className="form-control text-muted disabled"
                    defaultValue={selectedData && selectedData.id_product}
                    onClick={(event) =>
                      setSelectedData({
                        ...selectedData,
                        id_product: parseInt(event.target.value),
                      })
                    }
                  >
                    {all_product &&
                      all_product.map((value) => {
                        return (
                          <option
                            key={value.id}
                            value={value.id}
                            onClick={() =>
                              setSelectedData({
                                ...selectedData,
                                id_product: value.id,
                              })
                            }
                          >
                            {value.title}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </FormGroup>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setModalUpdate(!modalUpdate);
                  removeBodyCss();
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary waves-effect waves-light"
                onClick={() => onSubmitEdit()}
              >
                Submit
              </button>
            </div>
          </Modal>

          {/* Modal Delete */}
          <Modal
            isOpen={modalDelete}
            toggle={() => {
              setModalDelete(!modalDelete);
              removeBodyCss();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Delete Alergi Product
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalDelete(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Are you sure want to delete this alergi product?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setModalDelete(!modalDelete);
                  removeBodyCss();
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-danger waves-effect waves-light"
                onClick={() => {
                  props.deleteAlergiProduct(selectedData.id);
                  setModalDelete(!modalDelete);
                  removeBodyCss();
                  setSelectedData(null);
                  setTimeout(() => {
                    alert("Alergi Product Deleted Successfully!");
                  }, 2000);
                }}
              >
                Delete
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { alergi_product } = state.AlergiProduct;
  const { alergi_kulit } = state.AlergiKulit;
  const { product, option_product } = state.Product;
  return { alergi_product, alergi_kulit, product, option_product };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      readAlergiProduct,
      createAlergiProduct,
      updateAlergiProduct,
      deleteAlergiProduct,
      readAlergiKulit,
      readProduct,
    },
    dispatch
  );

export default connect(mapStatetoProps, mapDispatchToProps)(AlergiProduct);
