import React, { useEffect, useState } from "react";
import { Container, Card, CardBody, CardTitle, Table, Modal } from "reactstrap";
import {
  readProfileAdmin,
  updateProfileAdmin,
} from "../../store/pages/profileAdmin/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AvForm, AvField } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";

const ProfileAdmin = (props) => {
  const admin = props.admin;

  const [modalUpdate, setModalUpdate] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const onChangeSelectedData = (event) => {
    setSelectedData({
      ...selectedData,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmitUpdate = () => {
    props.updateProfileAdmin(selectedData, selectedData.id);
    setModalUpdate(!modalUpdate);
    removeBodyCss();
    setSelectedData(null);
    setTimeout(() => {
      alert("Profile Updated Successfully!");
    }, 2000);
  };

  useEffect(() => {
    props.readProfileAdmin();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Profile"} breadcrumbItem={"Profile"} />
          <Card>
            <CardBody>
              <CardTitle>Profile</CardTitle>
              <div
                className="col-md-12 mb-3"
                style={{ display: "grid", justifyItems: "flex-end" }}
              >
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => {
                    setModalUpdate(!modalUpdate);
                    setSelectedData(admin[0]);
                  }}
                >
                  <i className="bx bx-edit font-size-16 align-middle mr-2"></i>{" "}
                  Edit
                </button>
              </div>
              {admin && admin.length > 0 && (
                <div className="table-responsive">
                  <Table className="table table-centered table-bordered">
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <td>{admin && admin[0].name}</td>
                      </tr>
                      <tr>
                        <th>Email</th>
                        <td>{admin && admin[0].email}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              )}
            </CardBody>
          </Card>

          {/* Modal Update */}
          <Modal
            isOpen={modalUpdate}
            toggle={() => {
              setModalUpdate(!modalUpdate);
              removeBodyCss();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Edit Profile
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalUpdate(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <AvForm>
                <AvField
                  name="name"
                  label="Name"
                  value={selectedData && selectedData.name}
                  placeholder="ex: Admin"
                  type="text"
                  errorMessage="Enter Name"
                  validate={{ required: { value: true } }}
                  onChange={onChangeSelectedData}
                />
                <AvField
                  name="email"
                  label="Email"
                  value={selectedData && selectedData.email}
                  placeholder="ex: admin@skinadvisor.com"
                  type="email"
                  errorMessage="Enter Email"
                  validate={{ required: { value: true } }}
                  onChange={onChangeSelectedData}
                />
              </AvForm>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setModalUpdate(!modalUpdate);
                  removeBodyCss();
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              {selectedData && Object.values(selectedData).every((value) => value !== "") ? (
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={onSubmitUpdate}
                >
                  Submit
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  disabled
                >
                  Submit
                </button>
              )}
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { admin, message, loading } = state.ProfileAdmin;
  return { admin, message, loading };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      readProfileAdmin,
      updateProfileAdmin,
    },
    dispatch
  );

export default connect(mapStatetoProps, mapDispatchToProps)(ProfileAdmin);
