import {
  READ_USER,
  READ_USER_REJECT,
  READ_USER_FULFILLED,
  DELETE_USER,
  DELETE_USER_REJECT,
  DELETE_USER_FULFILLED,
} from "./actionTypes";

export const readUser = (date, name, kondisiKulit, alergiKulit, masalahKulit, limit, page) => {
  return {
    type: READ_USER,
    payload: {date, name, limit, page, kondisiKulit, alergiKulit, masalahKulit}
  };
};

export const readUserReject = (payload) => {
  return {
    type: READ_USER_REJECT,
    payload: payload,
  };
};

export const readUserFulfilled = (data) => {
  return {
    type: READ_USER_FULFILLED,
    payload: data,
  };
};

export const deleteUser = (id) => {
  return {
    type: DELETE_USER,
    payload: id,
  };
};

export const deleteUserReject = (payload) => {
  return {
    type: DELETE_USER_REJECT,
    payload: payload,
  };
};

export const deleteUserFulfilled = (payload) => {
  return {
    type: DELETE_USER_FULFILLED,
    payload: payload,
  };
};
