import {
  CREATE_ALERGI_PRODUCT,
  CREATE_ALERGI_PRODUCT_REJECT,
  CREATE_ALERGI_PRODUCT_FULFILLED,
  READ_ALERGI_PRODUCT,
  READ_ALERGI_PRODUCT_REJECT,
  READ_ALERGI_PRODUCT_FULFILLED,
  UPDATE_ALERGI_PRODUCT,
  UPDATE_ALERGI_PRODUCT_REJECT,
  UPDATE_ALERGI_PRODUCT_FULFILLED,
  DELETE_ALERGI_PRODUCT,
  DELETE_ALERGI_PRODUCT_REJECT,
  DELETE_ALERGI_PRODUCT_FULFILLED,
} from "./actionTypes";

export const createAlergiProduct = (data) => {
  return {
    type: CREATE_ALERGI_PRODUCT,
    payload: data,
  };
};

export const createAlergiProductReject = (payload) => {
  return {
    type: CREATE_ALERGI_PRODUCT_REJECT,
    payload: payload,
  };
};

export const createAlergiProductFulfilled = (payload) => {
  return {
    type: CREATE_ALERGI_PRODUCT_FULFILLED,
    payload: payload,
  };
};

export const readAlergiProduct = (search_data) => {
  return {
    type: READ_ALERGI_PRODUCT,
    payload: search_data,
  };
};

export const readAlergiProductReject = (payload) => {
  return {
    type: READ_ALERGI_PRODUCT_REJECT,
    payload: payload,
  };
};

export const readAlergiProductFulfilled = (data) => {
  return {
    type: READ_ALERGI_PRODUCT_FULFILLED,
    payload: data,
  };
};

export const updateAlergiProduct = (data, id) => {
  return {
    type: UPDATE_ALERGI_PRODUCT,
    payload: { data, id },
  };
};

export const updateAlergiProductReject = (payload) => {
  return {
    type: UPDATE_ALERGI_PRODUCT_REJECT,
    payload: payload,
  };
};

export const updateAlergiProductFulfilled = (payload) => {
  return {
    type: UPDATE_ALERGI_PRODUCT_FULFILLED,
    payload: payload,
  };
};

export const deleteAlergiProduct = (id) => {
  return {
    type: DELETE_ALERGI_PRODUCT,
    payload: id,
  };
};

export const deleteAlergiProductReject = (payload) => {
  return {
    type: DELETE_ALERGI_PRODUCT_REJECT,
    payload: payload,
  };
};

export const deleteAlergiProductFulfilled = (payload) => {
  return {
    type: DELETE_ALERGI_PRODUCT_FULFILLED,
    payload: payload,
  };
};
