import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Modal,
  Table,
  FormGroup,
} from "reactstrap";
import {
  readMasalahKulit,
  createMasalahKulit,
  updateMasalahKulit,
  deleteMasalahKulit,
} from "../../../store/pages/masalahKulit/actions";
import { readProduct } from "../../../store/pages/product/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { parseToRupiah } from "../../../helpers/index";
import { useHistory } from "react-router-dom";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Select from "react-select";

const MasalahKulit = (props) => {
  const masalah_kulit = props.masalah_kulit;
  const option_product = props.option_product;
  const history = useHistory();

  const [modalUpdate, setModalUpdate] = useState(false);
  const [modalDetail, setDetail] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedMulti, setSelectedMulti] = useState(null);

  const [imageUrl, setImageUrl] = useState(null);
  const [imageData, setImageData] = useState(null);

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const handleMulti = (selectedMulti) => {
    setSelectedMulti(selectedMulti);
  };
  const onChangeSelectedData = (event) => {
    setSelectedData({
      ...selectedData,
      [event.target.name]: event.target.value,
    });
  };
  const onInsertImage = async (event) => {
    const image = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImageUrl(reader.result);
        setImageData(image);
      }
    };
    reader.readAsDataURL(image);
  };
  const handleEdit = (value) => {
    let selectedProduct = [];
    value.product.map((value) => {
      return selectedProduct.push({ label: value.title, value: value.id });
    });
    setImageUrl(value.image);
    setSelectedData(value);
    setSelectedMulti(selectedProduct);
    setModalUpdate(!modalUpdate);
  };

  const onSubmitEdit = async () => {
    let id_product = [];
    await selectedMulti.map((value) => {
      return id_product.push(value.value);
    });
    let newData = new FormData();
    newData.append("name", selectedData.name);
    newData.append("description", selectedData.description);
    newData.append("show_masalah", selectedData.show_masalah);
    await selectedMulti.map((value) => {
      return newData.append("id_product[]", value.value);
    });
    newData.append("image", imageData);

    if (imageData === null) {
      props.updateMasalahKulit(
        { ...selectedData, id_product: id_product },
        selectedData.id,
        "text"
      );
    } else {
      props.updateMasalahKulit(newData, selectedData.id);
    }
    setModalUpdate(!modalUpdate);
    removeBodyCss();
    setSelectedData(null);
    setSelectedMulti(null);
    setImageData(null);
    setTimeout(() => {
      alert("Masalah Kulit Updated Successfully!");
    }, 2000);
  };
  const onSearch = (event) => {
    props.readMasalahKulit(event.target.value);
    history.push("/masalah-kulit");
  };

  useEffect(() => {
    props.readMasalahKulit("");
    props.readProduct("");
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Kondisi Kulit Management"}
            breadcrumbItem={"Masalah Kulit"}
          />
          <Card>
            <CardBody>
              <CardTitle>Masalah Kulit</CardTitle>
              <div
                className="col-md-12 mb-3"
                style={{
                  display: "grid",
                  justifyItems: "flex-end",
                  gridTemplateColumns: "1fr max-content",
                  columnGap: "8px",
                }}
              >
                <input
                  placeholder="Search Masalah Kulit"
                  onChange={onSearch}
                  style={{
                    borderRadius: "25px",
                    padding: "4px",
                    borderWidth: "1px",
                    borderColor: "gray",
                    borderStyle: "solid",
                    width: "250px",
                  }}
                />
              </div>
              <div className="table-responsive">
                <Table className="table table-centered">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Image</th>
                      <th scope="col">Name</th>
                      <th scope="col">Description</th>
                      <th scope="col">Show/Hidden</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {masalah_kulit &&
                      masalah_kulit.map((value, index) => {
                        return (
                          <tr key={value.id}>
                            <th scope="row">
                              <div>{index + 1}</div>
                            </th>
                            <td>
                              <img
                                width="75px"
                                src={value.image}
                                alt="Masalah Kulit Icon"
                              />
                            </td>
                            <td>{value.name}</td>
                            <td>{value.description}</td>
                            <td>{value.show_masalah}</td>
                            <td>
                              <div
                                style={{
                                  display: "grid",
                                  rowGap: "8px",
                                }}
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary waves-effect waves-light"
                                  style={{ minWidth: "max-content" }}
                                  onClick={() => {
                                    handleEdit(value);
                                  }}
                                >
                                  <i className="bx bx-edit font-size-16 align-middle mr-2"></i>{" "}
                                  Edit
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-info waves-effect waves-light"
                                  style={{ minWidth: "max-content" }}
                                  onClick={() => {
                                    setDetail(!modalDetail);
                                    setSelectedData(value);
                                  }}
                                >
                                  <i className="bx bx-show-alt font-size-16 align-middle mr-2"></i>{" "}
                                  Detail
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>

          {/* Modal Update */}
          <Modal
            isOpen={modalUpdate}
            toggle={() => {
              setModalUpdate(!modalUpdate);
              removeBodyCss();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Edit Masalah Kulit
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalUpdate(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <AvForm>
                <AvField
                  name="name"
                  label="Name"
                  placeholder="ex: Redness"
                  type="text"
                  errorMessage="Enter Masalah Kulit Name"
                  validate={{ required: { value: true } }}
                  value={selectedData && selectedData.name}
                  onChange={onChangeSelectedData}
                />
                <AvField
                  name="description"
                  label="Description"
                  type="textarea"
                  errorMessage="Enter Masalah Kulit Description"
                  validate={{ required: { value: true } }}
                  value={selectedData && selectedData.description}
                  onChange={onChangeSelectedData}
                />
                <div className="form-group">
                  <label className="col-form-label">Show/Hidden</label>
                  <div>
                    <select
                      name="product_id"
                      className="form-control"
                      selected={selectedData && selectedData.show_masalah}
                      defaultValue={selectedData && selectedData.show_masalah}
                      onClick={(event) => {
                        setSelectedData({
                          ...selectedData,
                          show_masalah: event.target.value,
                        });
                      }}
                    >
                      <option
                        value="Show"
                        onClick={(event) => {
                          setSelectedData({
                            ...selectedData,
                            show_masalah: event.target.value,
                          });
                        }}
                      >
                        Show
                      </option>
                      <option
                        value="Hidden"
                        onClick={(event) => {
                          setSelectedData({
                            ...selectedData,
                            show_masalah: event.target.value,
                          });
                        }}
                      >
                        Hidden
                      </option>
                    </select>
                  </div>
                </div>
                <FormGroup className="select2-container">
                  <label className="control-label">Masalah Kulit Product</label>
                  <Select
                    value={selectedMulti}
                    isMulti={true}
                    onChange={(...args) => {
                      handleMulti(...args);
                    }}
                    options={option_product}
                    classNamePrefix="select2-selection height-max-content"
                  />
                </FormGroup>
                <div className="form-group">
                  <label className="col-form-label">Image</label>
                  <div
                    style={{
                      borderStyle: "dashed",
                      borderWidth: "2px",
                      borderColor: "primary",
                      borderRadius: "10px",
                      position: "relative",
                      minHeight: "250px",
                      display: "grid",
                      alignItems: "center",
                    }}
                  >
                    {imageUrl === null ? (
                      <div
                        style={{
                          display: "grid",
                          justifyItems: "center",
                          fontSize: "24px",
                        }}
                      >
                        <i className="bx bx-cloud-upload align-middle"></i>
                        <div>Upload Image</div>
                      </div>
                    ) : (
                      <img
                        src={imageUrl}
                        style={{ width: "100%", borderRadius: "10px" }}
                        alt="HomeCard"
                      />
                    )}
                  </div>
                  <input
                    id="upload-home_card-image"
                    style={{ display: "none" }}
                    accept="image/*"
                    type="file"
                    onChange={onInsertImage}
                  />
                  <div className="text-center mt-4">
                    <label
                      type="button"
                      htmlFor="upload-home_card-image"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Upload File
                    </label>
                  </div>
                </div>
              </AvForm>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setModalUpdate(!modalUpdate);
                  removeBodyCss();
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              {selectedMulti === null ? (
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  disabled
                >
                  Submit
                </button>
              ) : selectedData &&
                Object.values(selectedData).every((values) => values !== "") ? (
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => {
                    onSubmitEdit();
                  }}
                >
                  Submit
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  disabled
                >
                  Submit
                </button>
              )}
            </div>
          </Modal>

          {/* Modal Detail */}
          <Modal
            size="lg"
            isOpen={modalDetail}
            toggle={() => {
              setDetail(!modalDetail);
              removeBodyCss();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Detail Alergi Product
              </h5>
              <button
                type="button"
                onClick={() => {
                  setDetail(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h5>Alergi Kulit</h5>
              <div className="form-group">
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "max-content 1fr max-content",
                    columnGap: "8px",
                  }}
                >
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <div>
                      <img
                        src={selectedData && selectedData.image}
                        width="50px"
                        alt="Masalah Product"
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <div>
                      <strong>Name :</strong>
                    </div>
                    &ensp;
                    <div>{selectedData && selectedData.name}</div>
                  </div>
                  <div style={{ alignItems: "center", display: "flex" }}>
                    <div>
                      <strong>Show/Hidden :</strong>
                    </div>
                    &ensp;
                    <div>{selectedData && selectedData.show_masalah}</div>
                  </div>
                </div>
                <div>
                  <strong>Description :</strong>
                  <div>{selectedData && selectedData.description}</div>
                </div>
              </div>
              <h5>Masalah Product</h5>
              <div className="form-group">
                <div className="table-responsive">
                  <Table className="table table-centered">
                    <thead>
                      <tr>
                        <th scope="col">No</th>
                        <th scope="col">Image</th>
                        <th scope="col">Name</th>
                        <th scope="col">Stock</th>
                        <th scope="col">Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedData &&
                        selectedData.product.map((value, index) => {
                          return (
                            <tr key={value.id}>
                              <th scope="row">
                                <div>{index + 1}</div>
                              </th>
                              <td>
                                <img
                                  src={value.thumbnail}
                                  alt="Product"
                                  width="50px"
                                />
                              </td>
                              <td>{value.title}</td>
                              <td>{value.stock}</td>
                              <td>{parseToRupiah(value.price || 0)}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setDetail(!modalDetail);
                  removeBodyCss();
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { masalah_kulit, message, loading } = state.MasalahKulit;
  const { option_product } = state.Product;
  return { masalah_kulit, message, loading, option_product };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      readMasalahKulit,
      createMasalahKulit,
      updateMasalahKulit,
      deleteMasalahKulit,
      readProduct,
    },
    dispatch
  );

export default connect(mapStatetoProps, mapDispatchToProps)(MasalahKulit);
