/* KONDISI KULIT */
export const READ_KONDISI_KULIT = "READ_KONDISI_KULIT";
export const READ_KONDISI_KULIT_REJECT = "READ_KONDISI_KULIT_REJECT";
export const READ_KONDISI_KULIT_FULFILLED = "READ_KONDISI_KULIT_FULFILLED";

export const CREATE_KONDISI_KULIT = "CREATE_KONDISI_KULIT";
export const CREATE_KONDISI_KULIT_REJECT = "CREATE_KONDISI_KULIT_REJECT";
export const CREATE_KONDISI_KULIT_FULFILLED = "CREATE_KONDISI_KULIT_FULFILLED";

export const UPDATE_KONDISI_KULIT = "UPDATE_KONDISI_KULIT";
export const UPDATE_KONDISI_KULIT_REJECT = "UPDATE_KONDISI_KULIT_REJECT";
export const UPDATE_KONDISI_KULIT_FULFILLED = "UPDATE_KONDISI_KULIT_FULFILLED";

export const DELETE_KONDISI_KULIT = "DELETE_KONDISI_KULIT";
export const DELETE_KONDISI_KULIT_REJECT = "DELETE_KONDISI_KULIT_REJECT";
export const DELETE_KONDISI_KULIT_FULFILLED = "DELETE_KONDISI_KULIT_FULFILLED";
