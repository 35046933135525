import {
  CREATE_HOME_CARD,
  CREATE_HOME_CARD_REJECT,
  CREATE_HOME_CARD_FULFILLED,
  READ_HOME_CARD,
  READ_HOME_CARD_REJECT,
  READ_HOME_CARD_FULFILLED,
  UPDATE_HOME_CARD,
  UPDATE_HOME_CARD_REJECT,
  UPDATE_HOME_CARD_FULFILLED,
  DELETE_HOME_CARD,
  DELETE_HOME_CARD_REJECT,
  DELETE_HOME_CARD_FULFILLED,
} from "./actionTypes";

export const createHomeCard = (data) => {
  return {
    type: CREATE_HOME_CARD,
    payload: data,
  };
};

export const createHomeCardReject = (payload) => {
  return {
    type: CREATE_HOME_CARD_REJECT,
    payload: payload,
  };
};

export const createHomeCardFulfilled = (payload) => {
  return {
    type: CREATE_HOME_CARD_FULFILLED,
    payload: payload,
  };
};

export const readHomeCard = () => {
  return {
    type: READ_HOME_CARD,
  };
};

export const readHomeCardReject = (payload) => {
  return {
    type: READ_HOME_CARD_REJECT,
    payload: payload,
  };
};

export const readHomeCardFulfilled = (data) => {
  return {
    type: READ_HOME_CARD_FULFILLED,
    payload: data,
  };
};

export const updateHomeCard = (data, id, type) => {
  return {
    type: UPDATE_HOME_CARD,
    payload: { data, id, type },
  };
};

export const updateHomeCardReject = (payload) => {
  return {
    type: UPDATE_HOME_CARD_REJECT,
    payload: payload,
  };
};

export const updateHomeCardFulfilled = (payload) => {
  return {
    type: UPDATE_HOME_CARD_FULFILLED,
    payload: payload,
  };
};

export const deleteHomeCard = (id) => {
  return {
    type: DELETE_HOME_CARD,
    payload: id,
  };
};

export const deleteHomeCardReject = (payload) => {
  return {
    type: DELETE_HOME_CARD_REJECT,
    payload: payload,
  };
};

export const deleteHomeCardFulfilled = (payload) => {
  return {
    type: DELETE_HOME_CARD_FULFILLED,
    payload: payload,
  };
};
