import React, { useState, useEffect } from "react";
import { Container, Card, CardBody, CardTitle, Modal, Table } from "reactstrap";
import {
  readAlergiKulit,
  createAlergiKulit,
  updateAlergiKulit,
  deleteAlergiKulit,
} from "../../../store/pages/alergiKulit/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useHistory } from "react-router-dom";

import Breadcrumbs from "../../../components/Common/Breadcrumb";

const AlergiKulit = (props) => {
  const alergi_kulit = props.alergi_kulit;
  const history = useHistory();

  const [modalCreate, setModalCreate] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [data, setData] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [search, setSearch] = useState("");

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const onChangeData = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };
  const onChangeSelectedData = (event) => {
    setSelectedData({
      ...selectedData,
      [event.target.name]: event.target.value,
    });
  };
  const onSearch = (event) => {
    props.readAlergiKulit(event.target.value);
    history.push("/alergi-kulit");
  };

  const ButtonSubmitCreate = () => {
    if (
      data &&
      Object.keys(data).length >= 2 &&
      Object.values(data).every((value) => value !== "")
    ) {
      return (
        <button
          type="button"
          className="btn btn-primary waves-effect waves-light"
          onClick={() => {
            props.createAlergiKulit(data);
            setModalCreate(!modalCreate);
            removeBodyCss();
            setData(null);
            setTimeout(() => {
              alert("Alergi Kulit Created Successfully!");
            }, 2000);
          }}
        >
          Submit
        </button>
      );
    } else {
      return (
        <button
          type="button"
          className="btn btn-primary waves-effect waves-light"
          disabled
          style={{ cursor: "default" }}
        >
          Submit
        </button>
      );
    }
  };

  useEffect(() => {
    props.readAlergiKulit(search);
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Kondisi Kulit Management"}
            breadcrumbItem={"Alergi Kulit"}
          />
          <Card>
            <CardBody>
              <CardTitle>Alergi Kulit</CardTitle>
              <div
                className="col-md-12 mb-3"
                style={{
                  display: "grid",
                  justifyItems: "flex-end",
                  gridTemplateColumns: "1fr max-content",
                  columnGap: "8px",
                }}
              >
                <input
                  placeholder="Search Alergi Kulit"
                  onChange={onSearch}
                  style={{
                    borderRadius: "25px",
                    padding: "4px",
                    borderWidth: "1px",
                    borderColor: "gray",
                    borderStyle: "solid",
                    width: "250px",
                  }}
                />
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => setModalCreate(!modalCreate)}
                >
                  <i className="bx bx-edit-alt font-size-16 align-middle mr-2"></i>{" "}
                  Insert
                </button>
              </div>
              <div className="table-responsive">
                <Table className="table table-centered">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Name</th>
                      <th scope="col">Description</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {alergi_kulit &&
                      alergi_kulit.map((value, index) => {
                        return (
                          <tr key={value.id}>
                            <th scope="row">
                              <div>{index + 1}</div>
                            </th>
                            <td>{value.name}</td>
                            <td>{value.description}</td>
                            <td>
                              <div
                                style={{
                                  display: "grid",
                                  rowGap: "8px",
                                }}
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary waves-effect waves-light"
                                  style={{ minWidth: "max-content" }}
                                  onClick={() => {
                                    setModalUpdate(!modalUpdate);
                                    setSelectedData(value);
                                  }}
                                >
                                  <i className="bx bx-edit font-size-16 align-middle mr-2"></i>{" "}
                                  Edit
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger waves-effect waves-light"
                                  style={{ minWidth: "max-content" }}
                                  onClick={() => {
                                    setModalDelete(!modalDelete);
                                    setSelectedData(value);
                                  }}
                                >
                                  <i className="bx bx-trash font-size-16 align-middle mr-2"></i>{" "}
                                  Delete
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>

          {/* Modal Create */}
          <Modal
            isOpen={modalCreate}
            toggle={() => {
              setModalCreate(!modalCreate);
              removeBodyCss();
              setData(null);
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Insert Alergi Kulit
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalCreate(false);
                  setData(null);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <AvForm>
                <AvField
                  name="name"
                  label="Name"
                  placeholder="ex: Alchohol"
                  type="text"
                  errorMessage="Enter Alergi Kulit Name"
                  validate={{ required: { value: true } }}
                  onChange={onChangeData}
                />
                <AvField
                  name="description"
                  label="Description"
                  type="textarea"
                  errorMessage="Enter Alergi Kulit Description"
                  validate={{ required: { value: true } }}
                  onChange={onChangeData}
                />
              </AvForm>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setModalCreate(!modalCreate);
                  removeBodyCss();
                  setData(null);
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              <ButtonSubmitCreate />
            </div>
          </Modal>

          {/* Modal Update */}
          <Modal
            isOpen={modalUpdate}
            toggle={() => {
              setModalUpdate(!modalUpdate);
              removeBodyCss();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Edit Alergi Kulit
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalUpdate(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <AvForm>
                <AvField
                  name="name"
                  label="Name"
                  placeholder="ex: Alchohol"
                  type="text"
                  errorMessage="Enter Alergi Kulit Name"
                  validate={{ required: { value: true } }}
                  value={selectedData && selectedData.name}
                  onChange={onChangeSelectedData}
                />
                <AvField
                  name="description"
                  label="Description"
                  type="textarea"
                  errorMessage="Enter Alergi Kulit Description"
                  validate={{ required: { value: true } }}
                  value={selectedData && selectedData.description}
                  onChange={onChangeSelectedData}
                />
              </AvForm>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setModalUpdate(!modalUpdate);
                  removeBodyCss();
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              {selectedData &&
              Object.values(selectedData).every((values) => values !== "") ? (
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => {
                    props.updateAlergiKulit(selectedData, selectedData.id);
                    setModalUpdate(!modalUpdate);
                    removeBodyCss();
                    setSelectedData(null);
                    setTimeout(() => {
                      alert("Alergi Kulit Updated Successfully!");
                    }, 2000);
                  }}
                >
                  Submit
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  disabled
                >
                  Submit
                </button>
              )}
            </div>
          </Modal>

          {/* Modal Delete */}
          <Modal
            isOpen={modalDelete}
            toggle={() => {
              setModalDelete(!modalDelete);
              removeBodyCss();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Delete Alergi Kulit
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalDelete(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Are you sure want to delete this alergi kulit?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setModalDelete(!modalDelete);
                  removeBodyCss();
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-danger waves-effect waves-light"
                onClick={() => {
                  props.deleteAlergiKulit(selectedData.id);
                  setModalDelete(!modalDelete);
                  removeBodyCss();
                  setSelectedData(null);
                  setTimeout(() => {
                    alert("Alergi Kulit Deleted Successfully!");
                  }, 2000);
                }}
              >
                Delete
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { alergi_kulit, message, loading } = state.AlergiKulit;
  return { alergi_kulit, message, loading };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      readAlergiKulit,
      createAlergiKulit,
      updateAlergiKulit,
      deleteAlergiKulit,
    },
    dispatch
  );

export default connect(mapStatetoProps, mapDispatchToProps)(AlergiKulit);
