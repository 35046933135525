import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Home
import Home from "../pages/Home/index";
import HomeCard from "../pages/HomeCard/index";
import KondisiKulit from "../pages/KondisiKulitManagement/KondisiKulit/index";
import AlergiKulit from "../pages/KondisiKulitManagement/AlergiKulit/index";
import MasalahKulit from "../pages/KondisiKulitManagement/MasalahKulit/index";
import User from "../pages/UserManagement/index";
import Product from "../pages/Product/index";
import AlergiProduct from "../pages/AlergiProduct/index";
import BasicInfo from "../pages/BasicInfo/index";
import Analisis from "../pages/Analisis/index";
import InfoKondisiKulit from "../pages/InfoKondisiKulit/index"
import ProfileAdmin from "../pages/ProfileAdmin/index"
const userRoutes = [
  { path: "/home", component: Home },
  { path: "/kondisi-kulit", component: KondisiKulit },
  { path: "/alergi-kulit", component: AlergiKulit },
  { path: "/masalah-kulit", component: MasalahKulit },
  { path: "/user-management", component: User },
  { path: "/home-card", component: HomeCard },
  { path: "/all-product", component: Product },
  { path: "/alergi-product", component: AlergiProduct },
  { path: "/basic-info", component: BasicInfo },
  { path: "/analisis", component: Analisis },
  { path: "/info-kondisi-kulit", component: InfoKondisiKulit },
  { path: "/profile", component: ProfileAdmin },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/home" /> },
];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
];

export { userRoutes, authRoutes };
