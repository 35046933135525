import {
  all,
  call,
  fork,
  takeEvery,
  put,
  takeLatest,
} from "redux-saga/effects";

import {
  READ_PROFILE_ADMIN,
  UPDATE_PROFILE_ADMIN,
} from "./actionTypes";
import {
  readProfileAdminReject,
  readProfileAdminFulfilled,
  updateProfileAdminReject,
  updateProfileAdminFulfilled,
} from "./actions";
import {
  readProfileAdminMethod,
  updateProfileAdminMethod,
} from "./method";

function* readProfileAdmin() {
  const data = yield call(readProfileAdminMethod);
  if (data.status === 200) {
    yield put(readProfileAdminFulfilled(data));
  }
  yield put(readProfileAdminReject(data));
}
function* updateProfileAdmin({ payload: { data, id } }) {
  const response = yield call(updateProfileAdminMethod, { data, id });
  if (response.status === 200) {
    yield put(updateProfileAdminFulfilled(response));
  }
  yield put(updateProfileAdminReject(updateProfileAdminReject(response)));
}

export function* watchReadProfileAdmin() {
  yield takeLatest(READ_PROFILE_ADMIN, readProfileAdmin);
}
export function* watchUpdateProfileAdmin() {
  yield takeEvery(UPDATE_PROFILE_ADMIN, updateProfileAdmin);
}

function* ProfileAdminSaga() {
  yield all([
    fork(watchReadProfileAdmin),
    fork(watchUpdateProfileAdmin),
  ]);
}

export default ProfileAdminSaga;
