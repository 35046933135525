export const READ_BASIC_INFO = "READ_BASIC_INFO";
export const READ_BASIC_INFO_REJECT = "READ_BASIC_INFO_REJECT";
export const READ_BASIC_INFO_FULFILLED = "READ_BASIC_INFO_FULFILLED";

export const CREATE_BASIC_INFO = "CREATE_BASIC_INFO";
export const CREATE_BASIC_INFO_REJECT = "CREATE_BASIC_INFO_REJECT";
export const CREATE_BASIC_INFO_FULFILLED = "CREATE_BASIC_INFO_FULFILLED";

export const UPDATE_BASIC_INFO = "UPDATE_BASIC_INFO";
export const UPDATE_BASIC_INFO_REJECT = "UPDATE_BASIC_INFO_REJECT";
export const UPDATE_BASIC_INFO_FULFILLED = "UPDATE_BASIC_INFO_FULFILLED";

export const DELETE_BASIC_INFO = "DELETE_BASIC_INFO";
export const DELETE_BASIC_INFO_REJECT = "DELETE_BASIC_INFO_REJECT";
export const DELETE_BASIC_INFO_FULFILLED = "DELETE_BASIC_INFO_FULFILLED";
