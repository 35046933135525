// @flow
import {
  CREATE_MASALAH_KULIT,
  CREATE_MASALAH_KULIT_REJECT,
  CREATE_MASALAH_KULIT_FULFILLED,
  READ_MASALAH_KULIT,
  READ_MASALAH_KULIT_REJECT,
  READ_MASALAH_KULIT_FULFILLED,
  UPDATE_MASALAH_KULIT,
  UPDATE_MASALAH_KULIT_REJECT,
  UPDATE_MASALAH_KULIT_FULFILLED,
  DELETE_MASALAH_KULIT,
  DELETE_MASALAH_KULIT_REJECT,
  DELETE_MASALAH_KULIT_FULFILLED,
} from "./actionTypes";

const INIT_STATE = {
  masalah_kulit: null,
  message: null,
  loading: false,
};

const MasalahKulit = (state = INIT_STATE, action) => {
  switch (action.type) {
    case READ_MASALAH_KULIT:
      return {
        ...state,
        lading: true,
      };
    case READ_MASALAH_KULIT_REJECT:
      return {
        ...state,
        message: action.payload.message,
        lading: true,
      };
    case READ_MASALAH_KULIT_FULFILLED:
      return {
        ...state,
        masalah_kulit: action.payload.result,
        lading: false,
      };
    case CREATE_MASALAH_KULIT:
      return {
        ...state,
        loading: true,
      };
    case CREATE_MASALAH_KULIT_REJECT:
      return {
        ...state,
        message: action.payload.message,
        loading: true,
      };
    case CREATE_MASALAH_KULIT_FULFILLED:
      return {
        ...state,
        masalah_kulit: action.payload.result,
        loading: false,
      };
    case UPDATE_MASALAH_KULIT:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_MASALAH_KULIT_REJECT:
      return {
        ...state,
        message: action.payload.message,
        loading: true,
      };
    case UPDATE_MASALAH_KULIT_FULFILLED:
      return {
        ...state,
        masalah_kulit: action.payload.result,
        lading: false,
      };
    case DELETE_MASALAH_KULIT:
      return {
        ...state,
        loading: true,
      };
    case DELETE_MASALAH_KULIT_REJECT:
      return {
        ...state,
        message: action.payload.message,
        loading: true,
      };
    case DELETE_MASALAH_KULIT_FULFILLED:
      return {
        ...state,
        masalah_kulit: action.payload.result,
        loading: false,
      };
    default:
      return state;
  }
};

export default MasalahKulit;
