import {
  CREATE_HOME,
  CREATE_HOME_REJECT,
  CREATE_HOME_FULFILLED,
  READ_HOME,
  READ_HOME_REJECT,
  READ_HOME_FULFILLED,
  UPDATE_HOME,
  UPDATE_HOME_REJECT,
  UPDATE_HOME_FULFILLED,
  DELETE_HOME,
  DELETE_HOME_REJECT,
  DELETE_HOME_FULFILLED,
} from "./actionTypes";

const INIT_STATE = {
  home: null,
  message: null,
  loading: false,
};

const Home = (state = INIT_STATE, action) => {
  switch (action.type) {
    case READ_HOME:
      return {
        ...state,
        lading: true,
      };
    case READ_HOME_REJECT:
      return {
        ...state,
        message: action.payload.message,
        lading: true,
      };
    case READ_HOME_FULFILLED:
      return {
        ...state,
        home: action.payload.result,
        lading: false,
      };
    case CREATE_HOME:
      return {
        ...state,
        loading: true,
      };
    case CREATE_HOME_REJECT:
      return {
        ...state,
        message: action.payload.message,
        loading: true,
      };
    case CREATE_HOME_FULFILLED:
      return {
        ...state,
        home: action.payload.result,
        loading: false,
      };
    case UPDATE_HOME:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_HOME_REJECT:
      return {
        ...state,
        message: action.payload.message,
        loading: true,
      };
    case UPDATE_HOME_FULFILLED:
      return {
        ...state,
        home: action.payload.result,
        lading: false,
      };
    case DELETE_HOME:
      return {
        ...state,
        loading: true,
      };
    case DELETE_HOME_REJECT:
      return {
        ...state,
        message: action.payload.message,
        loading: true,
      };
    case DELETE_HOME_FULFILLED:
      return {
        ...state,
        home: action.payload.result,
        loading: false,
      };
    default:
      return state;
  }
};

export default Home;
