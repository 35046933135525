require("dotenv").config();

export const createAlergiProductMethod = async (data) => {
  const response = await fetch(`${process.env.REACT_APP_API}/alergi-product`, {
    method: "POST",
    mode: "cors",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  return response.json();
};

export const readAlergiProductMethod = async (search_data) => {
  if (search_data !== "") {
    const response = await fetch(
      `${process.env.REACT_APP_API}/alergi-product?name=${search_data}`,
      {
        method: "GET",
        mode: "cors",
      }
    );
    return response.json();
  } else {
    const response = await fetch(
      `${process.env.REACT_APP_API}/alergi-product`,
      {
        method: "GET",
        mode: "cors",
      }
    );
    return response.json();
  }
};

export const updateAlergiProductMethod = async ({ data, id }) => {
  const response = await fetch(
    `${process.env.REACT_APP_API}/alergi-product/${id}`,
    {
      method: "PATCH",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    }
  );
  return response.json();
};

export const deleteAlergiProductMethod = async (id) => {
  const response = await fetch(
    `${process.env.REACT_APP_API}/alergi-product/${id}`,
    {
      method: "DELETE",
      mode: "cors",
    }
  );
  return response.json();
};
