import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Modal,
  Table,
  FormGroup,
} from "reactstrap";
import {
  readKondisiKulit,
  createKondisiKulit,
  updateKondisiKulit,
  deleteKondisiKulit,
} from "../../../store/pages/kondisiKulit/actions";
import { readProduct } from "../../../store/pages/product/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { parseToRupiah } from "../../../helpers/index";
import { useHistory } from "react-router-dom";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Select from "react-select";

const KondisiKulit = (props) => {
  const kondisi_kulit = props.kondisi_kulit;
  const option_product = props.option_product;
  const history = useHistory();

  const [modalCreate, setModalCreate] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [modalDetail, setDetail] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  const [data, setData] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [selectedMulti, setSelectedMulti] = useState(null);

  const [imageUrl, setImageUrl] = useState(null);
  const [imageData, setImageData] = useState(null);

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const handleMulti = (selectedMulti) => {
    setSelectedMulti(selectedMulti);
  };
  const onChangeData = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };
  const onChangeSelectedData = (event) => {
    setSelectedData({
      ...selectedData,
      [event.target.name]: event.target.value,
    });
  };
  const onInsertImage = async (event) => {
    const image = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImageUrl(reader.result);
        setImageData(image);
      }
    };
    reader.readAsDataURL(image);
  };
  const handleEdit = (value) => {
    let selectedProduct = [];
    value.product.map((value) => {
      return selectedProduct.push({ label: value.title, value: value.id });
    });
    setImageUrl(value.image);
    setSelectedData(value);
    setSelectedMulti(selectedProduct);
    setModalUpdate(!modalUpdate);
  };

  const onSubmitInsert = async () => {
    let newData = new FormData();
    newData.append("name", data.name);
    newData.append("description", data.description);
    await selectedMulti.map((value) => {
      return newData.append("id_product[]", value.value);
    });
    newData.append("image", imageData);

    props.createKondisiKulit(newData);

    setModalCreate(!modalCreate);
    removeBodyCss();
    setData(null);
    setSelectedMulti(null);
    setImageData(null);
    setImageUrl(null);
    setTimeout(() => {
      alert("Kondisi Kulit Created Successfully!");
    }, 2000);
  };
  const onSubmitEdit = async () => {
    let id_product = [];
    await selectedMulti.map((value) => {
      return id_product.push(value.value);
    });
    let newData = new FormData();
    newData.append("name", selectedData.name);
    newData.append("description", selectedData.description);
    await selectedMulti.map((value) => {
      return newData.append("id_product[]", value.value);
    });
    newData.append("image", imageData);

    if (imageData === null) {
      props.updateKondisiKulit(
        { ...selectedData, id_product: id_product },
        selectedData.id,
        "text"
      );
    } else {
      props.updateKondisiKulit(newData, selectedData.id);
    }
    setModalUpdate(!modalUpdate);
    removeBodyCss();
    setSelectedData(null);
    setSelectedMulti(null);
    setImageData(null);
    setTimeout(() => {
      alert("Kondisi Kulit Updated Successfully!");
    }, 2000);
  };
  const onSearch = (event) => {
    props.readKondisiKulit(event.target.value);
    history.push("/kondisi-kulit");
  };

  const ButtonSubmitCreate = () => {
    if (
      data &&
      Object.keys(data).length >= 2 &&
      Object.values(data).every((value) => value !== "") &&
      selectedMulti &&
      imageData
    ) {
      return (
        <button
          type="button"
          className="btn btn-primary waves-effect waves-light"
          onClick={() => {
            onSubmitInsert();
          }}
        >
          Submit
        </button>
      );
    } else {
      return (
        <button
          type="button"
          className="btn btn-primary waves-effect waves-light"
          disabled
          style={{cursor:'default'}}
        >
          Submit
        </button>
      );
    }
  };
  useEffect(() => {
    props.readKondisiKulit("");
    props.readProduct("");
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Kondisi Kulit Management"}
            breadcrumbItem={"Kondisi Kulit"}
          />
          <Card>
            <CardBody>
              <CardTitle>Kondisi Kulit</CardTitle>
              <div
                className="col-md-12 mb-3"
                style={{
                  display: "grid",
                  justifyItems: "flex-end",
                  gridTemplateColumns: "1fr max-content",
                  columnGap: "8px",
                }}
              >
                <input
                  placeholder="Search Kondisi Kulit"
                  onChange={onSearch}
                  style={{
                    borderRadius: "25px",
                    padding: "4px",
                    borderWidth: "1px",
                    borderColor: "gray",
                    borderStyle: "solid",
                    width: "250px",
                  }}
                />
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => {
                    setModalCreate(!modalCreate);
                    setImageUrl(null);
                  }}
                >
                  <i className="bx bx-edit-alt font-size-16 align-middle mr-2"></i>{" "}
                  Insert
                </button>
              </div>
              <div className="table-responsive">
                <Table className="table table-centered">
                  <thead>
                    <tr>
                      <th scope="col">No</th>
                      <th scope="col">Image</th>
                      <th scope="col">Name</th>
                      <th scope="col">Description</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {kondisi_kulit &&
                      kondisi_kulit.map((value, index) => {
                        return (
                          <tr key={value.id}>
                            <th scope="row">
                              <div>{index + 1}</div>
                            </th>
                            <td>
                              <img
                                width="75px"
                                src={value.image}
                                alt="Kondisi Kulit Icon"
                              />
                            </td>
                            <td>{value.name}</td>
                            <td>{value.description}</td>
                            <td>
                              <div
                                style={{
                                  display: "grid",
                                  rowGap: "8px",
                                }}
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary waves-effect waves-light"
                                  style={{ minWidth: "max-content" }}
                                  onClick={() => {
                                    handleEdit(value);
                                  }}
                                >
                                  <i className="bx bx-edit font-size-16 align-middle mr-2"></i>{" "}
                                  Edit
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-info waves-effect waves-light"
                                  style={{ minWidth: "max-content" }}
                                  onClick={() => {
                                    setDetail(!modalDetail);
                                    setSelectedData(value);
                                  }}
                                >
                                  <i className="bx bx-show-alt font-size-16 align-middle mr-2"></i>{" "}
                                  Detail
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-danger waves-effect waves-light"
                                  style={{ minWidth: "max-content" }}
                                  onClick={() => {
                                    setModalDelete(!modalDelete);
                                    setSelectedData(value);
                                  }}
                                >
                                  <i className="bx bx-trash font-size-16 align-middle mr-2"></i>{" "}
                                  Delete
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>

          {/* Modal Create */}
          <Modal
            isOpen={modalCreate}
            toggle={() => {
              setModalCreate(!modalCreate);
              removeBodyCss();
              setData(null);
              setSelectedMulti(null);
              setImageData(null);
              setImageUrl(null);
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Insert Kondisi Kulit
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalCreate(false);
                  setData(null);
                  setSelectedMulti(null);
                  setImageData(null);
                  setImageUrl(null);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <AvForm>
                <AvField
                  name="name"
                  label="Name"
                  placeholder="ex: Kulit Berminyak"
                  type="text"
                  errorMessage="Enter Kondisi Kulit Name"
                  validate={{ required: { value: true } }}
                  onChange={onChangeData}
                />
                <AvField
                  name="description"
                  label="Description"
                  type="textarea"
                  errorMessage="Enter Kondisi Kulit Description"
                  validate={{ required: { value: true } }}
                  onChange={onChangeData}
                />
                <FormGroup className="select2-container">
                  <label className="control-label">Kondisi Kulit Product</label>
                  <Select
                    isMulti={true}
                    onChange={(...args) => {
                      handleMulti(...args);
                    }}
                    options={option_product}
                    classNamePrefix="select2-selection height-max-content"
                  />
                </FormGroup>
                <div className="form-group">
                  <label className="col-form-label">Image</label>
                  <div
                    style={{
                      borderStyle: "dashed",
                      borderWidth: "2px",
                      borderColor: "primary",
                      borderRadius: "10px",
                      position: "relative",
                      minHeight: "250px",
                      display: "grid",
                      alignItems: "center",
                    }}
                  >
                    {imageUrl === null ? (
                      <div
                        style={{
                          display: "grid",
                          justifyItems: "center",
                          fontSize: "24px",
                        }}
                      >
                        <i className="bx bx-cloud-upload align-middle"></i>
                        <div>Upload Image</div>
                      </div>
                    ) : (
                      <img
                        src={imageUrl}
                        style={{ width: "100%", borderRadius: "10px" }}
                        alt="HomeCard"
                      />
                    )}
                  </div>
                  <input
                    id="upload-home_card-image"
                    style={{ display: "none" }}
                    accept="image/*"
                    type="file"
                    onChange={onInsertImage}
                  />
                  <div className="text-center mt-4">
                    <label
                      type="button"
                      htmlFor="upload-home_card-image"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Upload File
                    </label>
                  </div>
                </div>
              </AvForm>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setModalCreate(!modalCreate);
                  removeBodyCss();
                  setData(null);
                  setSelectedMulti(null);
                  setImageData(null);
                  setImageUrl(null);
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              <ButtonSubmitCreate />
            </div>
          </Modal>

          {/* Modal Update */}
          <Modal
            isOpen={modalUpdate}
            toggle={() => {
              setModalUpdate(!modalUpdate);
              removeBodyCss();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Edit Kondisi Kulit
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalUpdate(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <AvForm>
                <AvField
                  name="name"
                  label="Name"
                  placeholder="ex: Kulit Berminyak"
                  type="text"
                  errorMessage="Enter Kondisi Kulit Name"
                  validate={{ required: { value: true } }}
                  value={selectedData && selectedData.name}
                  onChange={onChangeSelectedData}
                />
                <AvField
                  name="description"
                  label="Description"
                  type="textarea"
                  errorMessage="Enter Kondisi Kulit Description"
                  validate={{ required: { value: true } }}
                  value={selectedData && selectedData.description}
                  onChange={onChangeSelectedData}
                />
                <FormGroup className="select2-container">
                  <label className="control-label">Kondisi Kulit Product</label>
                  <Select
                    value={selectedMulti}
                    isMulti={true}
                    onChange={(...args) => {
                      handleMulti(...args);
                    }}
                    options={option_product}
                    classNamePrefix="select2-selection height-max-content"
                  />
                </FormGroup>
                <div className="form-group">
                  <label className="col-form-label">Image</label>
                  <div
                    style={{
                      borderStyle: "dashed",
                      borderWidth: "2px",
                      borderColor: "primary",
                      borderRadius: "10px",
                      position: "relative",
                      minHeight: "250px",
                      display: "grid",
                      alignItems: "center",
                    }}
                  >
                    {imageUrl === null ? (
                      <div
                        style={{
                          display: "grid",
                          justifyItems: "center",
                          fontSize: "24px",
                        }}
                      >
                        <i className="bx bx-cloud-upload align-middle"></i>
                        <div>Upload Image</div>
                      </div>
                    ) : (
                      <img
                        src={imageUrl}
                        style={{ width: "100%", borderRadius: "10px" }}
                        alt="HomeCard"
                      />
                    )}
                  </div>
                  <input
                    id="upload-home_card-image"
                    style={{ display: "none" }}
                    accept="image/*"
                    type="file"
                    onChange={onInsertImage}
                  />
                  <div className="text-center mt-4">
                    <label
                      type="button"
                      htmlFor="upload-home_card-image"
                      className="btn btn-primary waves-effect waves-light"
                    >
                      Upload File
                    </label>
                  </div>
                </div>
              </AvForm>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setModalUpdate(!modalUpdate);
                  removeBodyCss();
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              {selectedMulti === null ? (
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  disabled
                >
                  Submit
                </button>
              ) : imageUrl === null ? (
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  disabled
                >
                  Submit
                </button>
              ) : selectedData &&
                Object.values(selectedData).every((values) => values !== "") ? (
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => {
                    onSubmitEdit();
                  }}
                >
                  Submit
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  disabled
                >
                  Submit
                </button>
              )}
            </div>
          </Modal>

          {/* Modal Detail */}
          <Modal
            size="lg"
            isOpen={modalDetail}
            toggle={() => {
              setDetail(!modalDetail);
              removeBodyCss();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Detail Kondisi Product
              </h5>
              <button
                type="button"
                onClick={() => {
                  setDetail(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h5>Kondisi Kulit</h5>
              <div className="form-group">
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "max-content 1fr max-content",
                    columnGap: "8px",
                  }}
                >
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <img
                      src={selectedData && selectedData.image}
                      height="100px"
                      alt="Kondisi Product"
                    />
                  </div>
                  <div>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <strong>Name :</strong>
                      &ensp;
                      <div>{selectedData && selectedData.name}</div>
                    </div>
                    <div>
                      <strong>Description :</strong>
                      <div>{selectedData && selectedData.description}</div>
                    </div>
                  </div>
                </div>
              </div>
              <h5>Kondisi Product</h5>
              <div className="form-group">
                <div className="table-responsive">
                  <Table className="table table-centered">
                    <thead>
                      <tr>
                        <th scope="col">No</th>
                        <th scope="col">Image</th>
                        <th scope="col">Name</th>
                        <th scope="col">Stock</th>
                        <th scope="col">Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedData &&
                        selectedData.product.map((value, index) => {
                          return (
                            <tr key={value.id}>
                              <th scope="row">
                                <div>{index + 1}</div>
                              </th>
                              <td>
                                <img
                                  src={value.thumbnail}
                                  alt="Product"
                                  width="50px"
                                />
                              </td>
                              <td>{value.title}</td>
                              <td>{value.stock}</td>
                              <td>{parseToRupiah(value.price || 0)}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setDetail(!modalDetail);
                  removeBodyCss();
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </Modal>

          {/* Modal Delete */}
          <Modal
            isOpen={modalDelete}
            toggle={() => {
              setModalDelete(!modalDelete);
              removeBodyCss();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Delete Kondisi Kulit
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalDelete(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Are you sure want to delete this kondisi kulit?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setModalDelete(!modalDelete);
                  removeBodyCss();
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-danger waves-effect waves-light"
                onClick={() => {
                  props.deleteKondisiKulit(selectedData.id);
                  setModalDelete(!modalDelete);
                  removeBodyCss();
                  setSelectedData(null);
                  setTimeout(() => {
                    alert("Kondisi Kulit Deleted Successfully!");
                  }, 2000);
                }}
              >
                Delete
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { kondisi_kulit, message, loading } = state.KondisiKulit;
  const { option_product } = state.Product;
  return { kondisi_kulit, message, loading, option_product };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      readKondisiKulit,
      createKondisiKulit,
      updateKondisiKulit,
      deleteKondisiKulit,
      readProduct,
    },
    dispatch
  );

export default connect(mapStatetoProps, mapDispatchToProps)(KondisiKulit);
