require("dotenv").config();

export const readProfileAdminMethod = async () => {
  const response = await fetch(`${process.env.REACT_APP_API}/admin`, {
    method: "GET",
    mode: "cors",
  });
  return response.json();
};

export const updateProfileAdminMethod = async ({ data, id }) => {
    const response = await fetch(`${process.env.REACT_APP_API}/admin/${id}`, {
      method: "PATCH",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });
    return response.json();
};