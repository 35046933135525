import {
  CREATE_INFO_KONDISI_KULIT,
  CREATE_INFO_KONDISI_KULIT_REJECT,
  CREATE_INFO_KONDISI_KULIT_FULFILLED,
  READ_INFO_KONDISI_KULIT,
  READ_INFO_KONDISI_KULIT_REJECT,
  READ_INFO_KONDISI_KULIT_FULFILLED,
  UPDATE_INFO_KONDISI_KULIT,
  UPDATE_INFO_KONDISI_KULIT_REJECT,
  UPDATE_INFO_KONDISI_KULIT_FULFILLED,
  DELETE_INFO_KONDISI_KULIT,
  DELETE_INFO_KONDISI_KULIT_REJECT,
  DELETE_INFO_KONDISI_KULIT_FULFILLED,
} from "./actionTypes";

export const createInfoKondisiKulit = (data) => {
  return {
    type: CREATE_INFO_KONDISI_KULIT,
    payload: data,
  };
};

export const createInfoKondisiKulitReject = (payload) => {
  return {
    type: CREATE_INFO_KONDISI_KULIT_REJECT,
    payload: payload,
  };
};

export const createInfoKondisiKulitFulfilled = (payload) => {
  return {
    type: CREATE_INFO_KONDISI_KULIT_FULFILLED,
    payload: payload,
  };
};

export const readInfoKondisiKulit = () => {
  return {
    type: READ_INFO_KONDISI_KULIT,
  };
};

export const readInfoKondisiKulitReject = (payload) => {
  return {
    type: READ_INFO_KONDISI_KULIT_REJECT,
    payload: payload,
  };
};

export const readInfoKondisiKulitFulfilled = (data) => {
  return {
    type: READ_INFO_KONDISI_KULIT_FULFILLED,
    payload: data,
  };
};

export const updateInfoKondisiKulit = (data, id) => {
  return {
    type: UPDATE_INFO_KONDISI_KULIT,
    payload: { data, id },
  };
};

export const updateInfoKondisiKulitReject = (payload) => {
  return {
    type: UPDATE_INFO_KONDISI_KULIT_REJECT,
    payload: payload,
  };
};

export const updateInfoKondisiKulitFulfilled = (payload) => {
  return {
    type: UPDATE_INFO_KONDISI_KULIT_FULFILLED,
    payload: payload,
  };
};

export const deleteInfoKondisiKulit = (id) => {
  return {
    type: DELETE_INFO_KONDISI_KULIT,
    payload: id,
  };
};

export const deleteInfoKondisiKulitReject = (payload) => {
  return {
    type: DELETE_INFO_KONDISI_KULIT_REJECT,
    payload: payload,
  };
};

export const deleteInfoKondisiKulitFulfilled = (payload) => {
  return {
    type: DELETE_INFO_KONDISI_KULIT_FULFILLED,
    payload: payload,
  };
};
