import {
  READ_PRODUCT,
  READ_PRODUCT_REJECT,
  READ_PRODUCT_FULFILLED,
} from "./actionTypes";

export const readProduct = (search_data) => {
  return {
    type: READ_PRODUCT,
    payload: search_data
  };
};

export const readProductReject = (payload) => {
  return {
    type: READ_PRODUCT_REJECT,
    payload: payload,
  };
};

export const readProductFulfilled = (data) => {
  return {
    type: READ_PRODUCT_FULFILLED,
    payload: data,
  };
};