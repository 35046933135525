import {
  all,
  call,
  fork,
  takeEvery,
  put,
  takeLatest,
} from "redux-saga/effects";

import {
  CREATE_HOME,
  READ_HOME,
  UPDATE_HOME,
  DELETE_HOME,
} from "./actionTypes";
import {
  createHomeReject,
  createHomeFulfilled,
  readHomeReject,
  readHomeFulfilled,
  updateHomeReject,
  updateHomeFulfilled,
  deleteHomeReject,
  deleteHomeFulfilled,
} from "./actions";
import {
  createHomeMethod,
  readHomeMethod,
  updateHomeMethod,
  deleteHomeMethod,
} from "./method";

function* readHome() {
  const data = yield call(readHomeMethod);
  if (data.status === 200) {
    yield put(readHomeFulfilled(data));
  }
  yield put(readHomeReject(data));
}
function* createHome({ payload: data }) {
  const response = yield call(createHomeMethod, data);
  if (response.status === 200) {
    yield put(createHomeFulfilled(response));
  }
  yield put(createHomeReject(createHomeReject(response)));
}
function* updateHome({ payload: { data, id, type } }) {
  const response = yield call(updateHomeMethod, { data, id, type });
  if (response.status === 200) {
    yield put(updateHomeFulfilled(response));
  }
  yield put(updateHomeReject(updateHomeReject(response)));
}
function* deleteHome({ payload: id }) {
  const response = yield call(deleteHomeMethod, id);
  if (response.status === 200) {
    yield put(deleteHomeFulfilled(response));
  }
  yield put(deleteHomeReject(deleteHomeReject(response)));
}

export function* watchReadHome() {
  yield takeLatest(READ_HOME, readHome);
}
export function* watchCreateHome() {
  yield takeEvery(CREATE_HOME, createHome);
}
export function* watchUpdateHome() {
  yield takeEvery(UPDATE_HOME, updateHome);
}
export function* watchDeleteHome() {
  yield takeEvery(DELETE_HOME, deleteHome);
}

function* HomeSaga() {
  yield all([
    fork(watchReadHome),
    fork(watchCreateHome),
    fork(watchUpdateHome),
    fork(watchDeleteHome),
  ]);
}

export default HomeSaga;
